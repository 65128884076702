<div class="onboarding-modal-container">
  <div class="d-flex d-md-none justify-content-center pt-10" (click)="bsModalRef.hide()">
    <img src="https://static-assets.vinlivt.de/icons/misc/mobile-gray-line.png" alt="line" />
  </div>

  <div class="modal-body">
    @if (currentStep > 0 && currentStep < 11) {
      <div class="row mb-15">
        <div class="col-8">
          <div class="section-title">Profildaten</div>
        </div>

        <div class="col-4 d-flex align-items-center justify-content-end">
          <div class="theme-color">{{ currentStep }} von 11</div>

          <i class="bi bi-check2-circle theme-color ml-2"></i>
        </div>
      </div>

      <div class="row mb-15">
        <div class="col-12">
          <div class="progress push">
            <div
              class="progress-bar"
              role="progressbar"
              style="background: var(--purple-color); width: {{ (currentStep * 100) / totalSteps }}%;"
              aria-valuenow="10"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </div>
    }

    <!-- Steps -->
    <div class="row">
      <div class="col-12 steps-content">
        @switch (currentStep) {
          @case (0) {
            <app-onboarding-advisor-step
              [person]="person"
              [loading]="loading"
              (triggerNextStep)="next()"
            ></app-onboarding-advisor-step>
          }
          @case (1) {
            <app-onboarding-relationship-step
              [personProfile]="personProfile"
              (triggerNextStep)="next()"
              (triggerPreviousStep)="previous()"
            ></app-onboarding-relationship-step>
          }
          @case (2) {
            <app-onboarding-profession-step
              [personProfile]="personProfile"
              (triggerNextStep)="next()"
              (triggerPreviousStep)="previous()"
            ></app-onboarding-profession-step>
          }
          @case (3) {
            <app-onboarding-health-insurance-step
              [personProfile]="personProfile"
              (triggerNextStep)="next()"
              (triggerPreviousStep)="previous()"
            ></app-onboarding-health-insurance-step>
          }
          @case (4) {
            <app-onboarding-children-step
              [personProfile]="personProfile"
              (triggerNextStep)="next()"
              (triggerPreviousStep)="previous()"
            ></app-onboarding-children-step>
          }
          @case (5) {
            <app-onboarding-mobility-step
              [personProfile]="personProfile"
              (triggerNextStep)="next()"
              (triggerPreviousStep)="previous()"
            ></app-onboarding-mobility-step>
          }
          @case (6) {
            <app-onboarding-misc-step
              [personProfile]="personProfile"
              (triggerNextStep)="next()"
              (triggerPreviousStep)="previous()"
            ></app-onboarding-misc-step>
          }
          @case (7) {
            <app-onboarding-pets-step
              [personProfile]="personProfile"
              (triggerNextStep)="next()"
              (triggerPreviousStep)="previous()"
            ></app-onboarding-pets-step>
          }
          @case (8) {
            <app-onboarding-housing-situation-step
              [personProfile]="personProfile"
              (triggerNextStep)="next()"
              (triggerPreviousStep)="previous()"
            ></app-onboarding-housing-situation-step>
          }
          @case (9) {
            <app-onboarding-property-ownership-step
              [personProfile]="personProfile"
              (triggerNextStep)="next()"
              (triggerPreviousStep)="previous()"
            ></app-onboarding-property-ownership-step>
          }
          @case (10) {
            <app-onboarding-provision-step
              [personProfile]="personProfile"
              (triggerNextStep)="next()"
              (triggerPreviousStep)="previous()"
            ></app-onboarding-provision-step>
          }
          @case (11) {
            <app-onboarding-user-info-step
              [person]="person"
              (triggerNextStep)="next()"
              (savePerson)="savePerson($event)"
              (triggerPreviousStep)="previous()"
            ></app-onboarding-user-info-step>
          }
          @case (12) {
            <app-onboarding-user-avatar-step
              [person]="person"
              (triggerNextStep)="next()"
              (triggerPreviousStep)="previous()"
            ></app-onboarding-user-avatar-step>
          }
        }
      </div>
    </div>
  </div>
</div>
