import { BankAccount } from './bank-account.model';

export class AnalysisCategory {
  id: number;
  name: string;
  accountIds: number[];
}

export class CreateUpdateAnalysis {
  name: string;
  accountIds: number[];
}

export class AnalysisReportRequest {
  analysisId?: number;
  accountIds?: number[];

  balanceStatistics?: string;
  monthlyExpensesStatistics?: AnalysisReportDatePicker;
  categoryStatistics?: AnalysisReportDatePicker;
  monthlyTurnoverStatistics?: AnalysisReportDatePicker;
  incomeOutcomeStatistics?: AnalysisReportDatePicker;
  includeAccounts?: boolean;
}

export class AnalysisReportDatePicker {
  start: string;
  end: string;
}

export class AnalysisReport {
  accounts: BankAccount[];
  statistics: AnalysisReportStatistics;
}

export class AnalysisReportStatistics {
  balance: ReportStatisticsBalance;
  monthlyExpenses: ReportStatisticsMonthlyExpanses;
  category: ReportStatisticsCategory;
  monthlyTurnover: ReportStatisticsMonthlyTurnover;
  incomeOutcome: ReportStatisticsIncomeOutcome;
}

export class ReportStatisticsBalance {
  dataPoints: DataPoints[];
}

export class ReportStatisticsMonthlyExpanses {
  dataPoints: DataPoints[];
  averageExpenses: number;
}
export class FormattedMonthlyExpanses {
  dates: string[];
  numbers: number[];
}

export class ReportStatisticsCategory {
  dataPoints: CategoryDataPoints[];
}

export class ReportStatisticsMonthlyTurnover {
  dataPoints: MonthlyTurnoverDataPoints[];
}

export class CategoryDataPoints {
  categoryId: number;
  name: string;
  color: string;
  amount: number;

  percent?: number;
}

export class ReportStatisticsIncomeOutcome {
  totalIncome: number;
  totalOutcome: number;
}

export class DataPoints {
  label: Date;
  value: number;
}

export class MonthlyTurnoverDataPoints {
  income: number;
  outcome: number;
  label: string;
}
