// Angular default imports
import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TOKEN_PROVIDER, WEBSOCKET_ENDPOINT, WebSocketModule } from '@vinlivt/websocket';

// Chart libs
import { Chart, registerables } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { deLocale } from 'ngx-bootstrap/locale';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ImageCropperModule } from 'ngx-image-cropper';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';

// Bootstrap imports
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxSliderModule } from 'ngx-slider-v2';
import { ToastrModule } from 'ngx-toastr';

// env & custom libs
import { environment } from '../environments/environment';

// modules
import { AppRoutesModule } from './app-routing.module';

// components
import { AppComponent } from './app.component';
import { LayoutModule } from './layout/app-layout.module';
import { AUTH_SERVICE_TOKEN } from './shared/service';
import { SharedModule } from './shared/shared.module';

Chart.register(annotationPlugin, ...registerables);

registerLocaleData(localeDe, localeDeExtra);
defineLocale('de', deLocale);

const mobileView: boolean = window.innerWidth <= 950;

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutesModule,
    LayoutModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ImageCropperModule,
    NgProgressHttpModule,
    WebSocketModule,
    NgxSliderModule,
    SharedModule.forRoot(),
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    CarouselModule.forRoot(),
    PopoverModule.forRoot(),
    TypeaheadModule.forRoot(),
    BsDatepickerModule.forRoot(),
    NgxSkeletonLoaderModule.forRoot({ animation: 'pulse', loadingText: 'This item is actually loading...' }),
    ToastrModule.forRoot({
      timeOut: 3000,
      preventDuplicates: true,
      closeButton: false,
      positionClass: mobileView ? 'toast-bottom-center' : 'toast-bottom-right',
    }),
    NgProgressModule.withConfig({
      spinnerPosition: 'right',
      thick: true,
      color: '#0303F9',
      spinner: false,
    }),
  ],
  exports: [],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'de-DE',
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'de-DE',
    },
    { provide: 'googleTagManagerId', useValue: 'GTM-P856T5T' },
    {
      provide: TOKEN_PROVIDER,
      useExisting: AUTH_SERVICE_TOKEN,
    },
    {
      provide: WEBSOCKET_ENDPOINT,
      useValue: environment.webSocketEndpoint,
    },
    provideEnvironmentNgxMask(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
