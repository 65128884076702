export interface ISteppers {
  firstStepperInfo: IStepper;
  secondStepperInfo: IStepper;
  thirdStepperInfo: IStepper;
}

export interface IStepper {
  finished: boolean;
  active: boolean;
  currentStep: number;
  totalSteps: number;
  title: string;
  id: number;
  stepperComponents: IStepperComponent[];
}

export interface IStepperComponent {
  type: any;
  active: boolean;
  finished: boolean;
  value: any;
  outputEvent: string;
  inputValue: string;
  stepNumber: number;
}

export interface IVinsafeOption {
  type: string;
  title: string;
  active: boolean;
}

export interface IIncome {
  netIncome: string;
  originOfMoney: string[];
}

export interface IKnowledgeExperience {
  yearExperienceOptions: string[];
  twoYearExperienceOptions: string[];
}

export interface IUserInfo {
  assetType: string;
  // academicalLevel: string;
  firstName: string;
  otherName: string;
  lastName: string;
  // realName: string;
  birthName: string;
  birthPlace: string;
  birthCountry: string;
}

export interface IUserAddress {
  street: string;
  houseNumber: string;
  postalCode: string;
  city: string;
  country: string;
  state: string;
  phone: string;
}

export interface IUserNationality {
  nationality: string[];
  taxPay: string;
  taxCountry: string;
  countryTaxId: string;
  USTaxResident: boolean;
}

export interface IUserIdentification {
  identificationType: string;
  identificationNumber: string;
  exhibitionDay: Date;
  validUntil: Date;
  issuedBy: string;
  placeOfIssue: string;
}

export interface IProfessionPractice {
  city: string;
  workBranch: string;
}

export interface IUserStatus {
  beneficialOrDirector: boolean;
  insideInfo: boolean;
  politicallyExposedPerson: boolean;
  taxIdentification: string;
}

export interface IBankInfo {
  owner: string;
  IBAN: string;
  // BIC: string;
  // bankName: string;
  // creditOrgLocation: string;
  terms1: boolean;
  terms2: boolean;
}

export interface IStartInvesting {
  depositType: string;
  value: string;
}

export interface IRiskPreview {
  riskValue: number;
  goalValue: string;
  birthdayDate: Date;
  investPeriod: string;
  annualNetIncome: string;
  originOfMoney: string[];
  activitiesNetIncome: string;
  originOfActivities: string[];
  plannedAssets: string;
}

export interface IConfirmations {
  itsMe: boolean;
  itIsMyActivities: boolean;
  entrustEvergreen: boolean;
  emailProvision: boolean;
}
