import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

import { ImageService } from './image.service';

import { CommunicationContractResponse, VinhubChat } from '../model';

@Injectable({
  providedIn: 'root',
})
export class ContractsHubService {
  constructor(
    private readonly http: HttpClient,
    private readonly imageService: ImageService,
  ) {}

  public loadContracts(): Observable<CommunicationContractResponse> {
    return this.http
      .get<CommunicationContractResponse>(environment.apiEndpoint + '/b2c/communication/contract')
      .pipe(this.imageService.loadImages('myAdvisor.profilePictureUrl', 'myAdvisor.company.companyLogoUrl'));
  }

  public startConversation(uid: string = null): Observable<VinhubChat> {
    return this.http.get<VinhubChat>(`${environment?.apiEndpoint}/b2c/contractsmessage/start?uid=${uid}`);
  }
}
