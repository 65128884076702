import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ImageService } from './image.service';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

import { CompanySettings } from '../model';

@Injectable({
  providedIn: 'root',
})
export class CompanySettingsService {
  constructor(
    private readonly http: HttpClient,
    private readonly imageService: ImageService,
  ) {}

  public getCompanySettingsData(): Observable<CompanySettings> {
    return this.http
      .get<CompanySettings>(environment.apiEndpoint + '/b2c/companysettings')
      .pipe(this.imageService.loadImages('companyLogoUrl'));
  }
}
