import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FormsModule } from '@angular/forms';
import { NgClass } from '@angular/common';

import { ModalsSafeAreaService } from '../../../global-store';
import { AdvisorService } from '../../../service';

import { Advisor } from '../../../model';

@Component({
  standalone: true,
  selector: 'app-broker-pin-modal',
  templateUrl: './broker-pin-modal.component.html',
  styleUrls: ['./broker-pin-modal.component.scss'],
  imports: [TooltipModule, FormsModule, NgClass],
})
export class BrokerPinModalComponent implements OnInit {
  @Output() action = new EventEmitter<Advisor>();

  @ViewChild('tan1') tan1: ElementRef;
  @ViewChild('tan2') tan2: ElementRef;
  @ViewChild('tan3') tan3: ElementRef;
  @ViewChild('tan4') tan4: ElementRef;
  @ViewChild('tan5') tan5: ElementRef;
  @ViewChild('tan6') tan6: ElementRef;

  public safeAreaBottom: number;

  public errorMessage: string;
  public invalidPinMessage: boolean = false;
  public isPinVerified: boolean = false;
  public isLoading: boolean = false;
  public assignedAdvisor: Advisor;

  public uid: string;
  public ref: string;

  constructor(
    private advisorService: AdvisorService,
    private modalsSafeAreaService: ModalsSafeAreaService,
  ) {}

  public ngOnInit(): void {
    this.safeAreaBottom = this.modalsSafeAreaService.getSafeAreaBottom();
  }

  private findTanByName(tanName: string, value: number): void {
    switch (tanName) {
      case 'tan1':
        this.tan1.nativeElement.value = value;
        this.tan2.nativeElement.focus();
        break;
      case 'tan2':
        this.tan2.nativeElement.value = value;
        this.tan3.nativeElement.focus();
        break;
      case 'tan3':
        this.tan3.nativeElement.value = value;
        this.tan4.nativeElement.focus();
        break;
      case 'tan4':
        this.tan4.nativeElement.value = value;
        this.tan5.nativeElement.focus();
        break;
      case 'tan5':
        this.tan5.nativeElement.value = value;
        this.tan6.nativeElement.focus();
        break;
      case 'tan6':
        this.tan6.nativeElement.value = value;
        this.assignAdvisorByPin();
        break;
      default:
        break;
    }
  }

  protected isNumber(event, tan): boolean {
    switch (event.target.value) {
      case '1':
        this.findTanByName(tan.name, 1);
        break;
      case '2':
        this.findTanByName(tan.name, 2);
        break;
      case '3':
        this.findTanByName(tan.name, 3);
        break;
      case '4':
        this.findTanByName(tan.name, 4);
        break;
      case '5':
        this.findTanByName(tan.name, 5);
        break;
      case '6':
        this.findTanByName(tan.name, 6);
        break;
      case '7':
        this.findTanByName(tan.name, 7);
        break;
      case '8':
        this.findTanByName(tan.name, 8);
        break;
      case '9':
        this.findTanByName(tan.name, 9);
        break;
      case '0':
        this.findTanByName(tan.name, 0);
        break;
      default:
        break;
    }

    const charCode = event.which ? event.which : event.keyCode;

    // Only numbers 0-9
    if (charCode < 12 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  private assignAdvisorByPin(): void {
    this.isLoading = true;

    const tan =
      this.tan1.nativeElement.value +
      this.tan2.nativeElement.value +
      this.tan3.nativeElement.value +
      this.tan4.nativeElement.value +
      this.tan5.nativeElement.value +
      this.tan6.nativeElement.value;

    this.advisorService.assignToAdvisor(tan).subscribe({
      next: (advisor: Advisor): void => {
        this.isPinVerified = true;
        this.invalidPinMessage = false;
        this.isLoading = false;
        this.assignedAdvisor = advisor;
      },
      error: (error): void => {
        console.log(error);
        this.isPinVerified = false;
        this.invalidPinMessage = true;
        this.isLoading = false;
        setTimeout(() => this.tan1.nativeElement.focus(), 400);
      },
    });
  }

  protected moveToPreviousTan(num: number): void {
    if (num === 1) {
      this.tan1.nativeElement.value = '';
      this.tan1.nativeElement.focus();
      return;
    }
    if (num === 2) {
      this.tan2.nativeElement.value = '';
      this.tan2.nativeElement.focus();
      return;
    }
    if (num === 3) {
      this.tan3.nativeElement.value = '';
      this.tan3.nativeElement.focus();
      return;
    }
    if (num === 4) {
      this.tan4.nativeElement.value = '';
      this.tan4.nativeElement.focus();
      return;
    }
    if (num === 5) {
      this.tan5.nativeElement.value = '';
      this.tan5.nativeElement.focus();
      return;
    }
  }

  protected onPaste(event: ClipboardEvent): void {
    let clipboardData: DataTransfer = event.clipboardData;
    let pin: string = clipboardData.getData('text');

    const pinArray: string[] = pin.split('');
    this.tan1.nativeElement.value = pinArray[0];
    this.tan2.nativeElement.value = pinArray[1];
    this.tan3.nativeElement.value = pinArray[2];
    this.tan4.nativeElement.value = pinArray[3];
    this.tan5.nativeElement.value = pinArray[4];
    this.tan6.nativeElement.value = pinArray[5];

    this.assignAdvisorByPin();
  }
}
