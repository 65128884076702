export class BrokerMandateData {
  created: Date;
  approved: boolean;
  approvedAt: Date;
  submittedToPool: boolean;
}

export class MandatePersonData {
  id: number;
  firstName: string;
  lastName: string;
  street: string;
  houseNumber: string;
  zip: number;
  city: string;
  birthday: string;
}
