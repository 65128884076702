<div class="mobile-bottom-navigation-container" [style.padding-bottom.px]="setCorrectSafeAreaByDevice()">
  <ul class="nav-main" [ngClass]="{ 'remove-column-repeat': !isDev }">
    <li>
      <a class="p-0 bottom-nav-link d-flex flex-column" routerLink="/dashboard" routerLinkActive="active">
        <img
          [ngClass]="{ 'nav-icon-active': router.url.includes('/dashboard') }"
          alt="nav icon"
          class="nav-icon"
          src="https://static-assets.vinlivt.de/icons/line-icons/svg/home_gray.svg"
        />

        <span [ngClass]="{ 'nav-text-active': router.url.includes('/dashboard') }" class="nav-text text-12">
          &Uuml;bersicht
        </span>
      </a>
    </li>

    <li>
      <a
        class="p-0 bottom-nav-link d-flex flex-column"
        data-action="layout"
        data-toggle="layout"
        routerLink="/contracts"
        routerLinkActive="active"
      >
        <img
          [ngClass]="{ 'nav-icon-active': router.url.includes('/contracts') }"
          alt="nav icon"
          class="nav-icon"
          src="https://static-assets.vinlivt.de/icons/line-icons/svg/folder-gray.svg"
        />

        <span [ngClass]="{ 'nav-text-active': router.url.includes('/contracts') }" class="nav-text text-12">
          Vertr&auml;ge
        </span>
      </a>
    </li>

    <li>
      <a
        class="p-0 bottom-nav-link d-flex flex-column"
        data-toggle="layout"
        routerLink="/bank-card-manager"
        routerLinkActive="active"
      >
        <img
          [ngClass]="{ 'nav-icon-active': router.url.includes('/bank-card-manager') }"
          alt="nav icon"
          class="nav-icon"
          src="https://static-assets.vinlivt.de/icons/line-icons/svg/wallet-gray.svg"
        />

        <span [ngClass]="{ 'nav-text-active': router.url.includes('/bank-card-manager') }" class="nav-text text-12">
          Konten
        </span>
      </a>
    </li>

    @if (isDev) {
      <li>
        <a
          class="p-0 bottom-nav-link d-flex flex-column"
          routerLinkActive="active"
          routerLink="/wealth"
          data-toggle="layout"
        >
          <img
            src="https://static-assets.vinlivt.de/icons/line-icons/svg/chart_gray.svg"
            class="nav-icon"
            [ngClass]="{ 'nav-icon-active': router.url.includes('/wealth') }"
            alt="nav icon"
          />

          <span class="nav-text text-12" [ngClass]="{ 'nav-text-active': router.url.includes('/wealth') }">
            Vermögen
          </span>
        </a>
      </li>
    }

    <li>
      <a
        class="p-0 bottom-nav-link d-flex flex-column"
        data-toggle="layout"
        routerLink="/vinhub"
        routerLinkActive="active"
      >
        <img
          [ngClass]="{ 'nav-icon-active': router.url.includes('/vinhub') }"
          alt="nav icon"
          class="nav-icon"
          src="https://static-assets.vinlivt.de/icons/line-icons/svg/chat-gray.svg"
        />
        @if (unreadMessages > 0) {
          <div class="unread-messages-bubble"></div>
        }

        <span [ngClass]="{ 'nav-text-active': router.url.includes('/vinhub') }" class="nav-text text-12"> Chats </span>
      </a>
    </li>
  </ul>
</div>
