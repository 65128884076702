import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { Person } from '../../../model';

@Component({
  standalone: true,
  selector: 'app-onboarding-advisor-step',
  templateUrl: 'onboarding-advisor-step.component.html',
  styleUrls: ['onboarding-advisor-step.component.scss'],
  imports: [NgxSkeletonLoaderModule],
})
export class OnboardingAdvisorStepComponent {
  @Input() loading: boolean;
  @Input() person: Person;

  @Output() triggerNextStep = new EventEmitter();

  constructor() {}
}
