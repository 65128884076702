import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { mergeMap, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

import {
  BankAccount,
  BankAccountTransaction,
  BankAccountTransactionsRequest,
  BankAccountTransactionsResponse,
  BankCardManagerData,
  BankConnectionDto,
  DetectedContract,
  Thresholds,
  TransactionTag,
  UpdateAccountNameAndThresholds,
} from '../model';
import { UiChangeTriggersService } from '../global-store';
import { AssetGroup, VinlivtUploadService } from '@vinlivt/upload';

@Injectable({
  providedIn: 'root',
})
export class OpenBankingService {
  public constructor(
    private readonly http: HttpClient,
    private readonly uiChangeTriggersService: UiChangeTriggersService,
    private readonly vinlivtUploadService: VinlivtUploadService,
  ) {}

  public checkProviderAccess(): Observable<any> {
    return this.http.get<any>(`${environment.apiEndpoint}/open-banking/bank-connection/has-any`, {
      observe: 'response',
    });
  }

  public getWebFormToken(): Observable<string> {
    return this.http.post(
      `${environment.apiEndpoint}/open-banking/bank-connection/import?theme=${this.uiChangeTriggersService.themeSetting}`,
      null,
      { responseType: 'text' },
    );
  }

  public getBankAccountList(include_hidden: boolean = false): Observable<BankConnectionDto[]> {
    return this.http.get<BankConnectionDto[]>(`${environment.apiEndpoint}/open-banking/bank-connection`, {
      params: { include_hidden },
    });
  }

  public getBankCards(include_hidden: boolean = false): Observable<BankAccount[]> {
    return this.http.get<BankAccount[]>(`${environment.apiEndpoint}/open-banking/account`, {
      params: { include_hidden },
    });
  }

  public deleteBankAccount(id: string): Observable<void> {
    return this.http.delete<void>(`${environment.apiEndpoint}/open-banking/bank-connection/${id}`);
  }

  public getBankCardManagerData(): Observable<BankCardManagerData> {
    return this.http.get<BankCardManagerData>(`${environment.apiEndpoint}/open-banking/dashboard`);
  }

  public getBankAccountTransactions(
    bankAccountTransactionsRequest?: BankAccountTransactionsRequest,
  ): Observable<BankAccountTransactionsResponse> {
    let params: HttpParams = new HttpParams();

    if (bankAccountTransactionsRequest) {
      Object.keys(bankAccountTransactionsRequest).forEach((key: string): void => {
        const value = (bankAccountTransactionsRequest as any)[key];
        if (value !== undefined && value !== null && value !== '') {
          if (Array.isArray(value)) {
            value.forEach((arrayValue): void => {
              params = params.append(key, arrayValue);
            });
          } else {
            params = params.set(key, value);
          }
        }
      });
    }

    return this.http.get<BankAccountTransactionsResponse>(`${environment.apiEndpoint}/open-banking/transaction`, {
      params,
    });
  }

  public transactionDetail(id: string): Observable<any> {
    return this.http.get<any>(`${environment.apiEndpoint}/b2c/account/transaction/detail/${id}`);
  }

  public reportWrongTransaction(transaction: BankAccountTransaction): Observable<void> {
    return this.http.post<void>(environment.apiEndpoint + '/b2c/transaction/report/issue', transaction);
  }

  public getTransactionAttachment(transactionId: number): Observable<AssetGroup[]> {
    return this.http.get<AssetGroup[]>(
      `${environment.apiEndpoint}/open-banking/transaction/${transactionId}/attachment`,
    );
  }

  public postTransactionAttachment(files: File[], transactionId: number): Observable<AssetGroup[]> {
    return this.http
      .post(
        `${environment.apiEndpoint}/open-banking/transaction/${transactionId}/attachment?count=${files.length}`,
        null,
        {
          responseType: 'text',
        },
      )
      .pipe(
        map((res: string) => res.split('\n')),
        mergeMap((uploadUrls: string[]) => this.vinlivtUploadService.uploadFiles(uploadUrls, files)),
      );
  }

  public deleteTransactionAttachment(transactionId: number, assetGroupId: number): Observable<void> {
    return this.http.delete<void>(
      `${environment.apiEndpoint}/open-banking/transaction/${transactionId}/attachment/${assetGroupId}`,
    );
  }

  public updateBankConnection(
    bankConnectionId: string | number,
    importNewAccounts: boolean = false,
    updateCredentials: boolean = false,
  ): Observable<string | null> {
    return this.http
      .post(
        `${environment.apiEndpoint}/open-banking/bank-connection/${bankConnectionId}/update?import_new_accounts=${importNewAccounts}&update_credentials=${updateCredentials}&theme=${this.uiChangeTriggersService.themeSetting}`,
        null,
        { responseType: 'text', observe: 'response' },
      )
      .pipe(map((response: HttpResponse<any>) => (response.status === 204 ? null : response.body)));
  }

  public getAccountThresholds(id: string): Observable<Thresholds> {
    return this.http.get<Thresholds>(`${environment.apiEndpoint}/open-banking/account/${id}/thresholds`);
  }

  public updateAccountAndThresholds(id: string, data: UpdateAccountNameAndThresholds): Observable<void> {
    return this.http.post<void>(`${environment.apiEndpoint}/open-banking/account/${id}`, data);
  }

  public getDetectedContracts(): Observable<DetectedContract[]> {
    return this.http.get<DetectedContract[]>(`${environment.apiEndpoint}/open-banking/contract`);
  }

  public getTransactionTags(): Observable<TransactionTag[]> {
    return this.http.get<TransactionTag[]>(`${environment.apiEndpoint}/open-banking/tag`);
  }

  public postTransactionTag(tagName: string): Observable<TransactionTag> {
    return this.http.post<TransactionTag>(`${environment.apiEndpoint}/open-banking/tag`, tagName);
  }

  public editTransactionTag(tag: TransactionTag): Observable<TransactionTag> {
    return this.http.put<TransactionTag>(`${environment.apiEndpoint}/open-banking/tag/${tag.id}`, tag.name);
  }

  public deleteTransactionTag(id: number): Observable<void> {
    return this.http.delete<void>(`${environment.apiEndpoint}/open-banking/tag/${id}`);
  }

  public assignTagToTransaction(transactionId: number, tagId: number): Observable<void> {
    return this.http.post<void>(
      `${environment.apiEndpoint}/open-banking/transaction/${transactionId}/tag/${tagId}`,
      null,
    );
  }

  public dismissTagFromTransaction(transactionId: number, tagId: number): Observable<void> {
    return this.http.delete<void>(`${environment.apiEndpoint}/open-banking/transaction/${transactionId}/tag/${tagId}`);
  }
}
