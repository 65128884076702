import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

import { StatisticsResponse } from '../model';

@Injectable({
  providedIn: 'root',
})
export class StatisticService {
  constructor(private http: HttpClient) {}

  public loadStatistics(): Observable<StatisticsResponse> {
    return this.http.get<StatisticsResponse>(`${environment.apiEndpoint}/b2c/statistics/load`);
  }
}
