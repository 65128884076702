import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

import { IDamageReport, IPostDamageReport } from '../model';

@Injectable({
  providedIn: 'root',
})
export class DamageReportService {
  constructor(private http: HttpClient) {}

  public getDamageReports(contractUid: string): Observable<IDamageReport[]> {
    return this.http.get<IDamageReport[]>(`${environment.apiEndpoint}/b2c/damages/contract/${contractUid}`);
  }

  public postDamageReport(payload: IPostDamageReport, files: File[]): Observable<IDamageReport> {
    const formData: FormData = new FormData();

    const payloadBlob: Blob = new Blob([JSON.stringify(payload)], {
      type: 'application/json',
    });

    formData.append('payload', payloadBlob);
    if (files) {
      Array.from(files).forEach((file) => {
        if (file.size / 1024 / 1024 <= 10.0) {
          formData.append('file', file, file.name);
        }
      });
    }

    return this.http.post<IDamageReport>(`${environment.apiEndpoint}/b2c/damages/save`, formData);
  }
}
