import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { forkJoin } from 'rxjs';

import { OnboardingAdvisorStepComponent } from './onboarding-advisor-step/onboarding-advisor-step.component';
import { OnboardingRelationshipStepComponent } from './onboarding-relationship-step/onboarding-relationship-step.component';
import { OnboardingProfessionStepComponent } from './onboarding-profession-step/onboarding-profession-step.component';
import { OnboardingHealthInsuranceStepComponent } from './onboarding-health-insurance-step/onboarding-health-insurance-step.component';
import { OnboardingChildrenStepComponent } from './onboarding-children-step/onboarding-children-step.component';
import { OnboardingMobilityStepComponent } from './onboarding-mobility-step/onboarding-mobility-step.component';
import { OnboardingMiscStepComponent } from './onboarding-misc-step/onboarding-misc-step.component';
import { OnboardingPetsStepComponent } from './onboarding-pets-step/onboarding-pets-step.component';
import { OnboardingHousingSituationStepComponent } from './onboarding-housing-situation-step/onboarding-housing-situation-step.component';
import { OnboardingPropertyOwnershipStepComponent } from './onboarding-property-ownership-step/onboarding-property-ownership-step.component';
import { OnboardingProvisionStepComponent } from './onboarding-provision-step/onboarding-provision-step.component';

import { PensionCheckService, UserSettingsService } from '../../service';

import { Advisor, Person, PersonProfile } from '../../model';
import { OnboardingUserInfoStepComponent } from './onboarding-user-info-step/onboarding-user-info-step.component';
import { OnboardingUserAvatarStepComponent } from './onboarding-user-avatar-step/onboarding-user-avatar-step.component';

@Component({
  standalone: true,
  selector: 'app-onboarding-modal',
  templateUrl: 'onboarding-modal.component.html',
  styleUrls: ['onboarding-modal.component.scss'],
  imports: [
    OnboardingAdvisorStepComponent,
    OnboardingRelationshipStepComponent,
    OnboardingProfessionStepComponent,
    OnboardingHealthInsuranceStepComponent,
    OnboardingChildrenStepComponent,
    OnboardingMobilityStepComponent,
    OnboardingMiscStepComponent,
    OnboardingPetsStepComponent,
    OnboardingHousingSituationStepComponent,
    OnboardingPropertyOwnershipStepComponent,
    OnboardingProvisionStepComponent,
    OnboardingUserInfoStepComponent,
    OnboardingUserAvatarStepComponent,
  ],
})
export class OnboardingModalComponent implements OnInit {
  @Output() triggerOnboardingFinish = new EventEmitter<boolean>();

  public loading: boolean = true;

  public person: Person = new Person();
  public advisorInfo: Advisor = new Advisor();
  public personProfile: PersonProfile = new PersonProfile();

  public currentStep: number = 0;
  public totalSteps: number = 10;

  constructor(
    protected bsModalRef: BsModalRef,
    private userSettingsService: UserSettingsService,
    private pensionCheckService: PensionCheckService,
  ) {}

  public ngOnInit(): void {
    this.getServices();
  }

  private getServices(): void {
    const personRequest = this.userSettingsService.getUserProfile();
    const personProfileRequest = this.pensionCheckService.loadPensionCheck();

    forkJoin([personRequest, personProfileRequest]).subscribe({
      next: ([person, personProfile]): void => {
        this.person = person;
        this.advisorInfo = person.advisor;
        this.replaceAdvisorWithSupervisor();

        this.personProfile = personProfile?.personProfile ?? new PersonProfile();
        this.loading = false;
      },
      error: (error): void => {
        console.log(error);
      },
    });
  }

  private replaceAdvisorWithSupervisor(): void {
    if (this.advisorInfo?.supervisor?.id) {
      this.advisorInfo.firstName = this.advisorInfo.supervisor.firstName;
      this.advisorInfo.lastName = this.advisorInfo.supervisor.lastName;
      this.advisorInfo.profilePictureUrl = this.advisorInfo.supervisor.profilePicture;
    }
  }

  protected previous(): void {
    this.currentStep = this.currentStep - 1;
  }

  protected next(): void {
    // save person profile changes
    if (this.currentStep > 0 && this.currentStep < 11) {
      this.pensionCheckService.edit(this.personProfile).subscribe();
      this.triggerOnboardingFinish.emit(true);
    }

    if (this.currentStep === 12) {
      this.bsModalRef.hide();
    } else {
      this.currentStep = this.currentStep + 1;
    }
  }

  protected savePerson(person: Person): void {
    this.person = person;
  }
}
