import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import installNativeMockBridge from './native-mock';

async function main(): Promise<void> {
  if (environment.production) {
    enableProdMode();
  } else {
    if (window.navigator.userAgent.includes('native-mock') && !('native' in window)) {
      installNativeMockBridge();
    }
  }

  console.debug('Bootstrapping application');
  await platformBrowserDynamic().bootstrapModule(AppModule);
  document.body.removeAttribute('style');
  document.getElementById('splash-image')?.remove();
  console.debug('Bootstrap successful');

  window.native?.applicationReady();
}

main()
  .catch(err => {
    console.error('Boostrap application failed', err);
    window.native?.applicationFailure();
  });
