<div class="advisor-check-modal-container" [style.padding-bottom.px]="safeAreaBottom">
  <div class="d-flex d-md-none justify-content-center pt-10" (click)="action.emit(null)">
    <img src="https://static-assets.vinlivt.de/icons/misc/mobile-gray-line.png" alt="line" />
  </div>

  <div class="modal-body w-100 p-20">
    <!-- Background Information Block -->
    <!--    @if (backgroundInformation) {-->
    <!--      <app-background-information-->
    <!--        [title]="title"-->
    <!--        [description]="description"-->
    <!--        [consentType]="consentType"-->
    <!--        (hideBackgroundInformation)="showBackGroundInformation($event, null)"-->
    <!--      ></app-background-information>-->
    <!--    } @else {-->
    <div class="d-flex align-items-center justify-content-center pb-20">
      <img
        src="https://static-assets.vinlivt.de/logo/icon/splash-logo-50x50.svg"
        class="img-fluid animate__animated animate__bounce"
        width="50"
        height="50"
      />
    </div>

    <div class="section-title text-center pb-10">Bevor es weiter geht...</div>
    <div class="pb-20 text-center theme-color">Bitte bestätige unsere Bestimmungen für die App.</div>

    <div class="theme-secondary-color text-center my-30" style="font-size: 12px">
      Durch Klicken auf "Registrieren" erkl&auml;rst du dich mit unserer
      <a
        class="text-purple"
        href="javascript:void(0);"
        (click)="redirectToExternalUrl('https://www.vinlivt.de/docs/datenschutzinformation-app')"
        >Datenschutzinformation
      </a>
      und den
      <a
        class="text-purple"
        href="javascript:void(0);"
        (click)="redirectToExternalUrl('https://www.vinlivt.de/docs/nutzungsbestimmungen-app')"
      >
        Nutzungsbestimmungen
      </a>
      freiwillig einverstanden. Das kannst du jederzeit widerrufen.
    </div>

    <button
      class="btn w-100 accept-btn btn-primary d-flex align-items-center justify-content-center mb-5"
      id="accept-consent-btn"
      (click)="action.emit(true)"
    >
      <span class="mr-5">
        <img
          style="filter: invert(100%) sepia(0%) saturate(4369%) hue-rotate(55deg) brightness(107%) contrast(116%)"
          src="https://static-assets.vinlivt.de/icons/line-icons/svg/login.svg"
          alt="login icon"
        />
      </span>
      Weiter zu App
    </button>
    <button class="btn w-100 cancel-btn text-purple" (click)="action.emit(false)">Nein danke ausloggen</button>
    <!--    }-->
  </div>
</div>
