import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

import {
  AdvisorAdvice,
  Contract,
  ContractGroup,
  ContractsRequest,
  DashboardContractsStatistics,
  MyContractsResponse,
} from '../model';

@Injectable({
  providedIn: 'root',
})
export class ContractsManagerService {
  constructor(private http: HttpClient) {}

  public checkHasAnyContracts(): Observable<any> {
    return this.http.get(`${environment.apiEndpoint}/b2c/contractsmanager/has-any-contracts`, { observe: 'response' });
  }

  public saveContract(contract: Contract): Observable<Contract> {
    return this.http.post<Contract>(`${environment.apiEndpoint}/b2c/contractsmanager/save`, contract);
  }

  public load(contractUid: string): Observable<Contract> {
    return this.http.get<Contract>(`${environment.apiEndpoint}/b2c/contractsmanager/load/${contractUid}`);
  }

  public loadMyContracts(contractsRequest: ContractsRequest): Observable<MyContractsResponse> {
    return this.http.post<MyContractsResponse>(
      `${environment.apiEndpoint}/b2c/contractsmanager/mycontracts`,
      contractsRequest,
    );
  }

  public loadDashboardContractsStatistics(): Observable<DashboardContractsStatistics[]> {
    return this.http.get<DashboardContractsStatistics[]>(
      `${environment.apiEndpoint}/b2c/contractsmanager/contractstatistics`,
    );
  }

  public deleteContract(contractUid: string): Observable<void> {
    return this.http.delete<void>(`${environment.apiEndpoint}/b2c/contractsmanager/delete/${contractUid}`);
  }

  public archiveContract(contractUid: string): Observable<void> {
    return this.http.get<void>(`${environment.apiEndpoint}/b2c/contractsmanager/archive/${contractUid}`);
  }

  public contractGroups(): Observable<ContractGroup[]> {
    return this.http.get<ContractGroup[]>(`${environment.apiEndpoint}/b2c/contractsmanager/contractgroups`);
  }

  public excludedContractGroups(): Observable<ContractGroup[]> {
    return this.http.get<ContractGroup[]>(`${environment.apiEndpoint}/b2c/contractsmanager/excludedcontractgroups`);
  }

  public loadTips(): Observable<AdvisorAdvice[]> {
    return this.http.get<AdvisorAdvice[]>(`${environment.apiEndpoint}/b2c/contractsmanager/tipps`);
  }
}
