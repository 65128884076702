import { Injectable } from '@angular/core';
import { JewelryDetails, UpdateJewelryDetails, WealthAttachments, WealthJewelry } from '../model';
import { BehaviorSubject, Observable, switchMap, tap, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WealthJewelryService {
  public filteredImages$ = new BehaviorSubject<{ attachmentUrl: string; id: string }[]>([]);
  private attachmentsSubject = new BehaviorSubject<any[]>([]);
  public attachments$ = this.attachmentsSubject.asObservable();

  constructor(private http: HttpClient) {}

  public saveJewelry(jewelry: WealthJewelry): Observable<any> {
    return this.http.post<any>(`${environment.apiEndpoint}/wealth/b2c/jewelry`, jewelry);
  }

  public getJewelryById(id: number): Observable<JewelryDetails> {
    return this.http.get<JewelryDetails>(`${environment.apiEndpoint}/wealth/b2c/jewelry/${id}`).pipe(
      map((res: JewelryDetails) => {
        this.updateAttachments(res.attachments);
        return res;
      }),
    );
  }

  public updateJewelryById(id: number, jewelry: UpdateJewelryDetails): Observable<JewelryDetails> {
    return this.http.put<JewelryDetails>(`${environment.apiEndpoint}/wealth/b2c/jewelry/${id}`, jewelry).pipe(
      map((res: JewelryDetails) => {
        this.updateAttachments(res.attachments);
        return res;
      }),
    );
  }

  public deleteJewelryById(id: number): Observable<any> {
    return this.http.delete<any>(`${environment.apiEndpoint}/wealth/b2c/jewelry/${id}`);
  }

  public uploadImageToJewelryUrl(url: string, file: File, id: number): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': file.type,
    });
    return this.http.put(url, file, { headers, observe: 'response', responseType: 'text' }).pipe(
      switchMap(() => this.getJewelryById(id)),
      tap((res: JewelryDetails): void => {
        this.updateAttachments(res.attachments);
      }),
    );
  }

  public updateAttachments(attachments: WealthAttachments[]): void {
    const allAttachments = attachments.flatMap((attachment: WealthAttachments) =>
      attachment.properties.map((property) => ({
        attachmentUrl: property.attachmentUrl,
        attachmentType: property.attachmentType,
        attachmentId: String(attachment.attachmentId),
      })),
    );
    this.attachmentsSubject.next(allAttachments);
  }

  public filterImagesByType(type: string): void {
    this.attachments$
      .pipe(
        map((attachments: any) =>
          attachments
            .filter((attachment) => attachment.attachmentType === type)
            .map((attachment) => ({
              attachmentUrl: attachment.attachmentUrl,
              id: attachment.attachmentId,
            })),
        ),
      )
      .subscribe((filteredImages) => {
        this.filteredImages$.next(filteredImages);
      });
  }

  private handleError(error: any): Observable<never> {
    console.error(error);
    return throwError(error);
  }
}
