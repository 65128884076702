<div class="contract-end-step-container">
  @if (!contractFinished) {
    <div class="fixed-height">
      <div class="row mb-20">
        <div class="col-12">
          <app-signing-pad (returnSignature)="addSignature($event)"></app-signing-pad>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="text-center">
            <div class="theme-secondary-color font-size-h6">
              Bitte unterschreibe mit deinem Finger im vorgehsehenen Feld.
              <i
                class="bi bi-info-circle text-purple pointer-cursor ml-1"
                [popover]="
                  'Du bist nur noch einen Schritt entfernt, deine Versicherungen unkompliziert und digital zu managen. Bitte unterschreibe in dem Unterschriftenfeld.'
                "
                placement="top"
                triggers="mouseenter:mouseleave"
                containerClass="br-16"
              ></i>
            </div>
          </div>
        </div>
      </div>

      <div class="row my-30">
        <div class="col-12">
          <div class="theme-color" style="font-size: 10px">
            Durch Klicken auf "Auftrag erteilen" erteile ich das Maklermandat bestehend aus
            <a class="text-purple pointer-cursor" (click)="showPreview.emit(types[0].type)">Maklermandat</a> und
            <a class="text-purple pointer-cursor" (click)="showPreview.emit(types[1].type)">Maklervollmacht</a>. Du
            erklärst dich mit der
            <a class="text-purple" href="javascript:void(0)" (click)="showPreview.emit(types[2].type)"
              >Datenschutzeinwilligung</a
            >
            gegenüber {{ company.name }} einverstanden. Du bestätigst die
            <a
              class="text-purple"
              href="javascript:void(0)"
              (click)="consentForCompany(consentType.PARTNER_INITIAL_INFORMATION)"
              >Erstinformation</a
            >
            gelesen zu haben.
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 text-center">
        <button
          class="btn btn-block btn-purple btn-rounded py-15 px-20"
          id="trigger-mandate-req"
          [disabled]="!signature"
          (click)="finishContract()"
        >
          @if (loadingMandate) {
            <i class="fa fa-spinner fa-spin"></i>
          } @else {
            Mandat erteilen
          }
        </button>
      </div>
    </div>
  } @else {
    <div class="fixed-height">
      <div class="row">
        <div class="col-12">
          <div class="d-flex align-items-center justify-content-center">
            <img
              src="https://static-assets.vinlivt.de/icons/premium/purple/320/Paper Jet 2.png"
              width="120"
              style="min-width: 120px"
              alt="jet image"
            />
          </div>
        </div>
      </div>

      <div class="scroll-container">
        <div class="row mb-30">
          <div class="col-12 d-flex justify-content-center">
            <div class="section-title">Vielen Dank für dein Mandat!</div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 d-flex align-items-center mb-10">
            <img
              class="mr-10 blue-icon"
              src="https://static-assets.vinlivt.de/icons/line-icons/svg/shield.svg"
              alt="shield image"
            />
            <div class="theme-color font-size-h6">Gratulation, du hast jetzt deinen persönlichen Makler.</div>
          </div>

          <div class="col-12 d-flex align-items-center mb-10">
            <img
              class="mr-10 blue-icon"
              src="https://static-assets.vinlivt.de/icons/line-icons/svg/shield.svg"
              alt="shield image"
            />
            <div class="theme-color font-size-h6">Ohne deine Zustimmung wird nichts unternommen.</div>
          </div>

          <div class="col-12 d-flex align-items-center mb-10">
            <img
              class="mr-10 blue-icon"
              src="https://static-assets.vinlivt.de/icons/line-icons/svg/shield.svg"
              alt="shield image"
            />
            <div class="theme-color font-size-h6">Deine Versicherungen werden jetzt darüber informiert.</div>
          </div>
        </div>

        <div class="row mt-30">
          <div class="col-12">
            <div class="theme-color text-12 text-center">
              Deine Unterlagen werden in diesem Moment für dich erstellt und sind, nachdem du dieses Fenster geschlossen
              hast in der App unter Maklermandat verfügbar.
              <i class="bi bi-info-circle text-purple ml-1"></i>
            </div>
          </div>
        </div>

        <!--        <div class="row mt-10">-->
        <!--          <ng-container *ngFor="let type of types">-->
        <!--            <div class="col-8 d-flex align-items-center">-->
        <!--              <i class="bi bi-filetype-pdf theme-color mr-3 text-20"></i>-->

        <!--              <div class="theme-color">{{ type.name }}</div>-->
        <!--            </div>-->

        <!--            <div class="col-4 text-right">-->
        <!--              <a class="text-purple mr-3" href="javascript:void(0)" (click)="downloadPDF.emit(type.type)">-->
        <!--                <i class="bi bi-download theme-color text-20"></i>-->
        <!--              </a>-->

        <!--              <a class="text-purple" href="javascript:void(0)" (click)="showPreview.emit(type.type)">-->
        <!--                <i class="bi bi-eye theme-color text-20"></i>-->
        <!--              </a>-->
        <!--            </div>-->
        <!--          </ng-container>-->
        <!--        </div>-->
      </div>
    </div>

    <div class="row">
      <div class="col-12 text-center">
        <button class="btn btn-rounded btn-purple py-15 px-20" (click)="closeModal.emit()">Schließen</button>
      </div>
    </div>
  }
</div>
