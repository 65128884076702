import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgxMaskDirective } from 'ngx-mask';
import * as $moment from 'moment';

import { MandatePersonData } from '../../../model';

import zipValidation from '../../../utils/form-validation/zip-validation';

@Component({
  standalone: true,
  selector: 'app-data-verification-step',
  templateUrl: 'data-verification-step.component.html',
  styleUrls: ['data-verification-step.component.scss'],
  imports: [NgxMaskDirective, FormsModule, ReactiveFormsModule],
})
export class DataVerificationStepComponent implements OnInit {
  @Input() person: MandatePersonData;

  @Output() verifierData = new EventEmitter<MandatePersonData>();
  @Output() triggerPreviousStep = new EventEmitter();

  public moment = $moment;
  public dataVerificationForm: FormGroup;

  constructor(private _formBuilder: FormBuilder) {}

  public ngOnInit(): void {
    this.dataVerificationForm = this._formBuilder.group({
      firstName: [this.person.firstName, [Validators.required]],
      lastName: [this.person.lastName, [Validators.required]],
      street: [this.person.street, [Validators.required]],
      houseNumber: [this.person.houseNumber, [Validators.required]],
      zip: [this.person.zip, [Validators.required]],
      city: [this.person.city, [Validators.required]],
      birthday: [this.person.birthday, [Validators.required, Validators.minLength(10)]],
    });
  }

  protected checkData(): void {
    if (this.dataVerificationForm.touched && this.dataVerificationForm.dirty) {
      this.verifierData.emit(this.dataVerificationForm.value);
    } else {
      this.verifierData.emit(null);
    }
  }

  protected readonly zipValidation = zipValidation;
}
