import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Options } from 'ngx-slider-v2';

import { PersonProfile } from '../../../model';

@Component({
  standalone: true,
  selector: 'app-onboarding-children-step',
  templateUrl: 'onboarding-children-step.component.html',
  styleUrls: ['onboarding-children-step.component.scss'],
})
export class OnboardingChildrenStepComponent implements OnInit, AfterViewInit {
  @ViewChild('childrenAmount') childrenAmount: ElementRef;

  @Input() personProfile: PersonProfile;

  @Output() triggerNextStep = new EventEmitter();
  @Output() triggerPreviousStep = new EventEmitter();

  protected hasChildren: boolean = false;
  protected maxChildren: number = 12;

  protected options: Options = {
    floor: 0,
    ceil: this.maxChildren,
    hideLimitLabels: true,
    translate: (value: number): string => {
      if (!isNaN(value) && value === 1) {
        return value + ' Kind';
      }
      if (!isNaN(value) && value > 1) {
        return value + ' Kinder';
      }
      if (!isNaN(value) && value < 1) {
        return 'Keine Kinder';
      }
    },
  };

  constructor() {}

  public ngOnInit(): void {
    if (this.personProfile.childrenAmount > 0 && typeof this.personProfile.childrenAmount !== 'undefined') {
      this.hasChildren = true;
    }
  }

  public ngAfterViewInit(): void {
    setTimeout((): void => {
      this.childrenAmount.nativeElement.focus();
    }, 800);
  }

  protected slideChildrenAmountChanged(): void {
    this.personProfile.childrenAmount = this.childrenAmount.nativeElement.value;

    if (this.personProfile.childrenAmount > 0) {
      this.hasChildren = true;
      if (this.personProfile.childrenAmount < this.personProfile.applicableChildrenSupport) {
        this.personProfile.applicableChildrenSupport = this.personProfile.childrenAmount;
      }
    } else {
      this.hasChildren = false;
      this.personProfile.applicableChildrenSupport = this.maxChildren;
      this.personProfile.applicableChildrenSupport = 0;
    }
  }
}
