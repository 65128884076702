import { Component, EventEmitter, Output } from '@angular/core';
import { PopoverModule } from 'ngx-bootstrap/popover';

@Component({
  standalone: true,
  selector: 'app-contract-start-step',
  templateUrl: 'contract-start-step.component.html',
  styleUrls: ['contract-start-step.component.scss'],
  imports: [PopoverModule],
})
export class ContractStartStepComponent {
  @Output() triggerNextStep = new EventEmitter();
  @Output() triggerSkipContract = new EventEmitter();

  constructor() {}
}
