export interface ITodos {
  open: ITodo[];
  closed: ITodo[];
}

export interface ITodo {
  id: number;
  uid: string;
  title: string;
  createdByAdvisor: ITodoCreatedByAdvisor;
  created: string;
  done: boolean;
  priorityLevel: number;
  editable?: boolean;
  contractId: number;
}

export interface ITodoCreatedByAdvisor {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  profilePicture: string;
}

export interface INewTodo {
  title: string;
  contractId: number;
}

export interface IEditTodo {
  id: number;
  title: string;
  done: boolean;
  priorityLevel: number;
}

export interface IPatchTodo {
  title?: string;
  done?: boolean;
  priorityLevel?: number;
  editable?: boolean;
}
