import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgClass } from '@angular/common';

import { SharedModule } from '../../../shared.module';

import { PersonProfile } from '../../../model';

import { PetStatusEnum } from '../../../enum';

@Component({
  standalone: true,
  selector: 'app-onboarding-pets-step',
  templateUrl: 'onboarding-pets-step.component.html',
  styleUrls: ['onboarding-pets-step.component.scss'],
  imports: [SharedModule, NgClass],
})
export class OnboardingPetsStepComponent {
  @Input() personProfile: PersonProfile;

  @Output() triggerNextStep = new EventEmitter();
  @Output() triggerPreviousStep = new EventEmitter();

  protected petsStatusEnum = PetStatusEnum;
  protected enumObject: string[] = Object.keys(PetStatusEnum);

  constructor() {}

  protected setValue(key: any): void {
    const index: number = this.personProfile.pets.findIndex((e: PetStatusEnum): boolean => e === key);
    if (key === 'NONE') {
      this.personProfile.pets = [];
      this.personProfile.pets.push(key);
      this.triggerNextStep.emit();
    } else {
      const none: number = this.personProfile.pets.findIndex((e: PetStatusEnum): boolean => e === ('NONE' as any));
      if (none > -1) {
        this.personProfile.pets.splice(none, 1);
      }
      if (index > -1) {
        this.personProfile.pets.splice(index, 1);
      } else {
        this.personProfile.pets.push(key);
      }
    }
  }
}
