import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { ModalsSafeAreaService } from '../../global-store';

@Component({
  standalone: true,
  selector: 'app-options-widget-modal',
  templateUrl: 'options-widget-modal.component.html',
  styleUrls: ['options-widget-modal.component.scss'],
})
export class OptionsWidgetModalComponent implements OnInit {
  @Output() action = new EventEmitter<string>();

  public safeAreaBottom: number;

  public options: any[];

  constructor(private modalsSafeAreaService: ModalsSafeAreaService) {}

  public ngOnInit(): void {
    this.safeAreaBottom = this.modalsSafeAreaService.getSafeAreaBottom();
  }
}
