export class Company {
  id: number;
  name: string;
  street: string;
  houseNumber: string;
  zipCode: string;
  city: string;
  phoneNumber: string;
  created: string;
  companyLogoUrl: string;
  showReferralInvitation: boolean;
}

export class CompanySettings {
  companyLogoUrl: string;
  showReferralInvitation: boolean;
}
