import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

import { IPermissionSettings } from '../model/permission-settings.interface';

@Injectable({
  providedIn: 'root',
})
export class PermissionSettingsService {
  constructor(private http: HttpClient) {}

  public getPermissionSettings(): Observable<IPermissionSettings[]> {
    return this.http.get<IPermissionSettings[]>(`${environment.apiEndpoint}/b2c/permissionsettings`);
  }

  public putPermissionSettingsType(type: string): Observable<any> {
    return this.http.put<any>(`${environment.apiEndpoint}/b2c/permissionsettings/${type}`, type);
  }
}
