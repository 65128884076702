import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, mergeMap, Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

import { UserNotifications } from '../model';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  public constructor(private readonly http: HttpClient) {}

  public permissionStatus(): Observable<NativeBridge.PermissionStatus> {
    return new Observable<void>((subscriber) => {
      subscriber.next();
      const onVisibilityChange = (): void => {
        if (document.visibilityState === 'visible') {
          subscriber.next();
        }
      };

      document.addEventListener('visibilitychange', onVisibilityChange);
      return () => document.removeEventListener('visibilitychange', onVisibilityChange);
    }).pipe(mergeMap(() => from(window.native.getNotificationsPermissionStatus())));
  }

  public getUserNotifications(): Observable<UserNotifications[]> {
    return this.http.get<UserNotifications[]>(`${environment.apiEndpoint}/notification/b2c/settings/types`);
  }

  public updateUserNotifications(name: string, types: string[]): Observable<any> {
    return this.http.post(`${environment.apiEndpoint}/notification/b2c/settings/delivery/${name}`, types);
  }
}
