import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Advisor } from '../model';

@Injectable({
  providedIn: 'root',
})
export class AdvisorUpdateService {
  private advisor: BehaviorSubject<Advisor> = new BehaviorSubject<Advisor>(new Advisor());

  constructor() {}

  public getUpdatedAdvisor(): Observable<Advisor> {
    return this.advisor.asObservable();
  }

  public setUpdatedAdvisor(value: Advisor): void {
    this.advisor.next(value);
  }
}
