import { Component, EventEmitter, Input, Output } from '@angular/core';

import { MandateApproval } from '../../../model';

class PdfDocument {
  public readonly url: string;

  public constructor(private readonly blob: Blob) {
    this.url = URL.createObjectURL(blob);
  }

  public destroy(): void {
    URL.revokeObjectURL(this.url);
  }
}

type PdfType = 'MANDATE' | 'POWER_OF_ATTORNEY' | 'PRIVACY_CONSENT';

@Component({
  standalone: true,
  selector: 'app-contract-pending-view',
  templateUrl: 'contract-pending-view.component.html',
  styleUrls: ['contract-pending-view.component.scss'],
})
export class ContractPendingViewComponent {
  @Input() brokerMandate: MandateApproval;

  @Output() closeModal = new EventEmitter();
  @Output() downloadPDF = new EventEmitter<PdfType>();
  @Output() showPreview = new EventEmitter<PdfType>();

  public readonly documents: Partial<Record<PdfType, PdfDocument>> = {};
  public readonly types = [
    {
      type: 'MANDATE',
      name: 'Maklermandat',
    },
    {
      type: 'POWER_OF_ATTORNEY',
      name: 'Maklervollmacht',
    },
    {
      type: 'PRIVACY_CONSENT',
      name: 'Datenschutzeinwilligung',
    },
  ] as const;

  constructor() {}
}
