import { Component, ViewEncapsulation } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { DeviceDetectorService } from 'ngx-device-detector';

import { SharedModule } from '../../shared.module';

import { MediaPreviewBaseModalComponent } from '../media-preview-base-modal/media-preview-base-modal.component';

@Component({
  standalone: true,
  selector: 'app-media-preview-mobile-modal',
  templateUrl: 'media-preview-mobile-modal.component.html',
  styleUrls: ['media-preview-mobile-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [PdfViewerModule, SharedModule, NgxSkeletonLoaderModule],
})
export class MediaPreviewMobileModalComponent extends MediaPreviewBaseModalComponent {
  public constructor(modalRef: BsModalRef, deviceService: DeviceDetectorService) {
    super(modalRef, deviceService);
  }
}
