export class UserAccount {
  id: string;
  name: string;
  type: string;
  amount: number;
  currency: string;
  iban: string;
  customerSegment: string;
  icon: string;
  providerName: string;
  currentAmount: number;
  needsConsentApproval: boolean = false;
  date?: Date;
}
