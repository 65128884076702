import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ModalsSafeAreaService } from '../../../global-store';
import { ConsentService } from '../../../service';

@Component({
  standalone: true,
  selector: 'app-background-information',
  templateUrl: './background-information.component.html',
  styleUrls: ['./background-information.component.scss'],
})
export class BackgroundInformationComponent implements OnInit {
  @Input() title: string;
  @Input() description: string;
  @Input() consentType: string;
  @Input() companyId: number;

  @Output() hideBackgroundInformation = new EventEmitter();

  public safeAreaBottom: number;

  constructor(
    private consentService: ConsentService,
    private modalsSafeAreaService: ModalsSafeAreaService,
  ) {}

  public ngOnInit(): void {
    this.safeAreaBottom = this.modalsSafeAreaService.getSafeAreaBottom();

    this.consentService.consentByTypeAndCompany(this.consentType, this.companyId).subscribe((data): void => {
      this.title = data.title;
      this.description = data.html;
    });
  }
}
