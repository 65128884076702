export class BrokerMandateApprovalRequestModel {
  brokerMandateId: number;
  signatureBase64: string;
  contractIds: number[];
}

export class MandateApproval {
  status: string;
  brokerMandateId: number;
}
