import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { ModalsSafeAreaService } from '../../global-store';

@Component({
  standalone: true,
  selector: 'app-consent-modal',
  templateUrl: './consent-modal.component.html',
  styleUrls: ['./consent-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConsentModalComponent implements OnInit {
  @Output() action = new EventEmitter();

  public safeAreaBottom: number;

  public title: string;
  public description: string;

  constructor(
    public modalRef: BsModalRef,
    private modalsSafeAreaService: ModalsSafeAreaService,
  ) {}

  public ngOnInit(): void {
    this.safeAreaBottom = this.modalsSafeAreaService.getSafeAreaBottom();
  }
}
