import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgClass } from '@angular/common';

import { SharedModule } from '../../../shared.module';

import { PersonProfile } from '../../../model';

import { PropertyOwnershipStatusEnum } from '../../../enum';

@Component({
  standalone: true,
  selector: 'app-onboarding-property-ownership-step',
  templateUrl: 'onboarding-property-ownership-step.component.html',
  styleUrls: ['onboarding-property-ownership-step.component.scss'],
  imports: [SharedModule, NgClass],
})
export class OnboardingPropertyOwnershipStepComponent {
  @Input() personProfile: PersonProfile;

  @Output() triggerNextStep = new EventEmitter();
  @Output() triggerPreviousStep = new EventEmitter();

  protected propertyOwnership = PropertyOwnershipStatusEnum;
  protected property = PropertyOwnershipStatusEnum;
  protected enumObject: string[] = Object.keys(PropertyOwnershipStatusEnum);

  constructor() {}

  protected setValue(key: any): void {
    if (key === 'NONE') {
      this.personProfile.propertyOwnership = new Array<PropertyOwnershipStatusEnum>();
      this.personProfile.propertyOwnership.push(key);

      this.triggerNextStep.emit();
    } else {
      if (this.personProfile.propertyOwnership) {
        const index: number = this.personProfile.propertyOwnership.findIndex(
          (e: PropertyOwnershipStatusEnum): boolean => e === key,
        );
        if (index > -1) {
          this.personProfile.propertyOwnership.splice(index, 1);
        } else {
          this.personProfile.propertyOwnership.push(key);
        }
      } else {
        this.personProfile.propertyOwnership = new Array<PropertyOwnershipStatusEnum>();
        this.personProfile.propertyOwnership.push(key);
      }

      key = 'NONE';
      const noneIndex: number = this.personProfile.propertyOwnership.findIndex(
        (e: PropertyOwnershipStatusEnum): boolean => e === key,
      );
      if (noneIndex > -1) {
        this.personProfile.propertyOwnership.splice(noneIndex, 1);
      }
    }
  }
}
