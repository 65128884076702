import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, tap } from 'rxjs';

import { environment } from '../../../environments/environment';

import { UserSettingsService } from './user-settings.service';

import { Advisor } from '../model';

@Injectable({
  providedIn: 'root',
})
export class AdvisorService {
  constructor(
    private userSettingsService: UserSettingsService,
    private http: HttpClient,
  ) {}

  public assignToAdvisor(pin: number): Observable<Advisor> {
    return this.http
      .get<Advisor>(environment.apiEndpoint + '/b2c/advisor/assign/' + pin)
      .pipe(tap((): void => this.userSettingsService.clearProfileCache()));
  }
}
