import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgClass } from '@angular/common';

import { SharedModule } from '../../../shared.module';

import { PersonProfile } from '../../../model';

import { ProvisionTargetEnum } from '../../../enum';

@Component({
  standalone: true,
  selector: 'app-onboarding-provision-step',
  templateUrl: 'onboarding-provision-step.component.html',
  styleUrls: ['onboarding-provision-step.component.scss'],
  imports: [SharedModule, NgClass],
})
export class OnboardingProvisionStepComponent {
  @Input() personProfile: PersonProfile;

  @Output() triggerNextStep = new EventEmitter();
  @Output() triggerPreviousStep = new EventEmitter();

  protected provisionTarget = ProvisionTargetEnum;
  protected enumObject: string[] = Object.keys(ProvisionTargetEnum);

  constructor() {}

  protected setValue(key: any): void {
    if (this.personProfile.provisionTarget) {
      const index: number = this.personProfile.provisionTarget.findIndex(
        (e: ProvisionTargetEnum): boolean => e === key,
      );
      if (index > -1) {
        this.personProfile.provisionTarget.splice(index, 1);
      } else {
        this.personProfile.provisionTarget.push(key);
      }
    } else {
      this.personProfile.provisionTarget = new Array<ProvisionTargetEnum>();
      this.personProfile.provisionTarget.push(key);
    }
  }
}
