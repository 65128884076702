export default function zipValidation(event: any): void {
  const inputElement: HTMLInputElement = event.target as HTMLInputElement;
  const input: string = inputElement.value;
  const regex: RegExp = /^[0-9]{0,5}$/;

  if (!regex.test(input)) {
    inputElement.value = input.replace(/\D/g, '').substring(0, 5);
    this.person.zip = inputElement.value;
  } else {
    this.person.zip = input;
  }
}
