import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ChatMessageService {
  constructor(private http: HttpClient) {}

  public countTotalUnreadMessages(): Observable<number> {
    return this.http.get<number>(environment.apiEndpoint + '/b2c/chatmessage/totalunread');
  }
}
