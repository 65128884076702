import { Person } from './person';

import { AttachmentContractTypeEnum } from '../enum';

export class Attachment {
  id: number;
  originalFilename: string;
  fileName: string;
  contentType: string;
  extension: string;
  downloadUrl: string;
  assetUrl: string;
  title: string;
  size: string;
  image: boolean;
  description: string;
  uploadedByDisplayname: string;
  uploadedBy: Person;
  created: Date = new Date();
  rejected: boolean;
  attachmentRejectionReasonEnum: string;
  uploadedByUserType: string;
  attachmentContractTypeEnum: AttachmentContractTypeEnum;
  attachmentContractType: string;
}

export class TransactionAttachment {
  id: number;
  mainAsset: MainAsset;
  attachments: any;
}

export class MainAsset {
  contentType: string;
  downloadUrl: string;
  filename: string;
  filesize: number;
  metadata: Object;
}
