import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  constructor(private http: HttpClient) {}

  public sendInvite(clientsRequest: string[]): Observable<void> {
    return this.http.post<void>(`${environment.apiEndpoint}/b2c/person/invite`, clientsRequest);
  }
}
