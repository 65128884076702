import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgClass } from '@angular/common';
import { NgxMaskDirective } from 'ngx-mask';

import { UserSettingsService } from '../../../service';

import { Person } from '../../../model';

import zipValidation from '../../../utils/form-validation/zip-validation';

@Component({
  standalone: true,
  selector: 'app-onboarding-user-info-step',
  templateUrl: 'onboarding-user-info-step.component.html',
  styleUrls: ['onboarding-user-info-step.component.scss'],
  imports: [FormsModule, NgxMaskDirective, ReactiveFormsModule, NgClass],
})
export class OnboardingUserInfoStepComponent implements OnInit {
  @Input() person: Person;

  @Output() triggerNextStep = new EventEmitter();
  @Output() triggerPreviousStep = new EventEmitter();
  @Output() savePerson = new EventEmitter<Person>();

  protected userInfoForm: FormGroup;
  protected requestLoading: boolean = false;

  constructor(
    private _formBuilder: FormBuilder,
    private userSettingsService: UserSettingsService,
  ) {}

  public ngOnInit(): void {
    this.userInfoForm = this._formBuilder.group({
      birthday: [this.person.birthday, [Validators.required]],
      street: [this.person.street, [Validators.required]],
      houseNumber: [this.person.houseNumber, [Validators.required]],
      zip: [this.person.zip, [Validators.required, Validators.min(5)]],
      city: [this.person.city, [Validators.required]],
    });
  }

  protected saveUserInfo(): void {
    this.requestLoading = true;

    this.person.street = this.userInfoForm.controls['street'].value;
    this.person.houseNumber = this.userInfoForm.controls['houseNumber'].value;
    this.person.zip = this.userInfoForm.controls['zip'].value;
    this.person.city = this.userInfoForm.controls['city'].value;
    this.person.birthday = this.userInfoForm.controls['birthday'].value;

    this.userSettingsService.saveUserProfile(this.person).subscribe({
      next: (): void => {
        this.triggerNextStep.emit();
        this.savePerson.emit(this.person);

        this.requestLoading = false;
      },
      error: (error): void => {
        console.log(error);
        this.requestLoading = false;
      },
    });
  }

  protected readonly zipValidation = zipValidation;
}
