import { APP_INITIALIZER, InjectionToken, Provider } from '@angular/core';
import { TokenProvider } from '@vinlivt/websocket';
import Keycloak from 'keycloak-js';
import { environment } from '../../../environments/environment';
import { UiChangeTriggersService } from '../global-store';

export interface AuthService extends TokenProvider {
  readonly userId: string;
  readonly loggedIn: boolean;

  hasRole(role: string): boolean;

  isInGroup(group: string): boolean;

  updatePassword(): Promise<never>;

  register(loginHint: string, referral?: string): Promise<never>;

  logout(): Promise<never>;
}

export const AUTH_SERVICE_TOKEN = new InjectionToken<AuthService>('AuthService');

export const authServiceProvider: Provider = 'native' in window ? {
  provide: AUTH_SERVICE_TOKEN,
  useFactory: () => new MobileAuthService(),
  multi: false,
} : [
  {
    provide: AUTH_SERVICE_TOKEN,
    useFactory: (uiChangeTriggersService: UiChangeTriggersService) => new KeycloakAuthService(uiChangeTriggersService),
    deps: [UiChangeTriggersService],
    multi: false,
  },
  {
    provide: APP_INITIALIZER,
    useFactory: (service: KeycloakAuthService) => () => service.init(),
    multi: true,
    deps: [AUTH_SERVICE_TOKEN],
  },
];

class KeycloakAuthService implements AuthService {
  private instance: Keycloak | null = null;

  public constructor(private readonly uiChangeTriggersService: UiChangeTriggersService) {
  }

  public get userId(): string {
    return this.instance.tokenParsed!.sub!;
  }

  public get loggedIn(): boolean {
    return this.instance?.authenticated ?? false;
  }

  public async init(): Promise<void> {
    this.instance = new Keycloak({
      url: environment.keycloakUrl,
      realm: environment.keycloakRealm,
      clientId: 'webapp',
    });

    console.debug('[KeycloakService] Initializing Keycloak');
    await this.instance.init({
      checkLoginIframe: true,
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: location.origin + '/assets/silent-check-sso.html',
      enableLogging: !environment.production,
    });
    console.debug('[KeycloakService] Keycloak initialized');
  }

  public async getToken(): Promise<string> {
    try {
      if (await this.instance.updateToken(20)) {
        console.debug('[KeycloakService] Token updated');
      }
    } catch (e) {
      console.error('[KeycloakService] Failed to update token, fallback to login', e);
      return this.login();
    }

    return this.instance.token;
  }

  public hasRole(role: string): boolean {
    return this.instance?.tokenParsed?.realm_access?.roles?.includes(role) ?? false;
  }

  public isInGroup(group: string): boolean {
    return this.instance?.tokenParsed?.groups?.includes(group) ?? false;
  }

  public updatePassword(): Promise<never> {
    return this.login({action: 'UPDATE_PASSWORD'});
  }

  public register(loginHint: string, referral?: string): Promise<never> {
    const url = new URL(this.instance.createLoginUrl({
      action: 'register',
      loginHint: loginHint,
    }));
    url.searchParams.append('theme', this.uiChangeTriggersService.themeSetting);
    if (referral) {
      url.searchParams.append('referral', referral);
    }
    location.assign(url);
    return new Promise(() => {
      // never resolves
    });
  }

  public logout(): Promise<never> {
    const url = new URL(this.instance.createLogoutUrl());
    url.searchParams.append('theme', this.uiChangeTriggersService.themeSetting);
    location.replace(url);
    return new Promise(() => {
      // never resolves
    });
  }

  private login(options?: Keycloak.KeycloakLoginOptions): Promise<never> {
    const url = new URL(this.instance.createLoginUrl(options));
    url.searchParams.append('theme', this.uiChangeTriggersService.themeSetting);
    location.assign(url);
    return new Promise(() => {
      // never resolves
    });
  }
}

class AccessToken {
  public readonly userId: string;
  public readonly expiresAt: Date;
  public readonly roles: readonly string[];
  public readonly groups: readonly string[];

  public constructor(public readonly token: string) {
    const payload = JSON.parse(atob(token.split('.')[1]));
    this.userId = payload.sub;
    this.expiresAt = new Date(payload.exp * 1000);
    this.roles = payload.realm_access.roles;
    this.groups = payload.groups;
  }

  public get expired(): boolean {
    return new Date() > this.expiresAt;
  }
}

class MobileAuthService implements AuthService {
  public readonly loggedIn: boolean = true;
  private accessToken: AccessToken | null = null;

  public get userId(): string {
    if (!this.accessToken) {
      throw new Error('User ID not available');
    }

    return this.accessToken.userId;
  }

  public async getToken(): Promise<string> {
    if (!this.accessToken || this.accessToken.expired) {
      this.accessToken = new AccessToken(await window.native.getAccessToken());
    }

    return this.accessToken.token;
  }

  public hasRole(role: string): boolean {
    return this.accessToken?.roles?.includes(role) ?? false;
  }

  public isInGroup(group: string): boolean {
    return this.accessToken?.groups?.includes(group) ?? false;
  }

  public updatePassword(): Promise<never> {
    // TODO: Implement update password page
    throw new Error('TODO: Implement update password page');
  }

  public register(): Promise<never> {
    throw new Error('Registration not supported');
  }

  public logout(): Promise<never> {
    window.native.logout();
    return new Promise(() => {
      // never resolves
    });
  }
}
