import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

import { INewTodo, IPatchTodo, ITodo, ITodos } from '../model';

@Injectable({
  providedIn: 'root',
})
export class TodoService {
  constructor(private http: HttpClient) {}

  public getTodo(): Observable<ITodos> {
    return this.http.get<ITodos>(`${environment.apiEndpoint}/b2c/todos`);
  }

  public createTodo(todo: INewTodo): Observable<INewTodo> {
    return this.http.post<INewTodo>(`${environment.apiEndpoint}/b2c/todos`, todo);
  }

  public patchTodo(todoId: number, todo: IPatchTodo): Observable<ITodo> {
    return this.http.patch<ITodo>(`${environment.apiEndpoint}/b2c/todos/${todoId}`, todo);
  }

  public deleteTodo(todoId: number): Observable<void> {
    return this.http.delete<void>(`${environment.apiEndpoint}/b2c/todos/${todoId}`);
  }
}
