import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { retry } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

import { BrokerMandateApprovalRequestModel, MandatePersonData } from '../model';

@Injectable({
  providedIn: 'root',
})
export class BrokerMandateApprovalService {
  constructor(private http: HttpClient) {}

  public postBrokerMandateApproval(data: BrokerMandateApprovalRequestModel): Observable<any> {
    return this.http.post(`${environment.apiEndpoint}/b2c/brokermandateapproval`, data);
  }

  public getBrokerMandatePDF(type: string, download: boolean): Observable<any> {
    const url: string = `${environment.apiEndpoint}/b2c/brokermandate/pdf/${type}?download=${download}`;

    const authHeader: HttpHeaders = new HttpHeaders();
    authHeader.set('Accept', 'application/pdf');

    return this.http
      .get<any>(url, {
        responseType: 'blob' as 'json',
        headers: authHeader,
      })
      .pipe(retry(1));
  }

  public getMandatePersonData(): Observable<MandatePersonData> {
    return this.http.get<MandatePersonData>(`${environment.apiEndpoint}/b2c/mandatepersondata`);
  }

  public postMandatePersonData(data: MandatePersonData): Observable<MandatePersonData> {
    return this.http.post<MandatePersonData>(`${environment.apiEndpoint}/b2c/mandatepersondata`, data);
  }

  public revoke(): Observable<void> {
    return this.http.get<void>(`${environment.apiEndpoint}/b2c/brokermandateapproval/revoke`);
  }
}
