import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { ModalsSafeAreaService } from '../../global-store';

@Component({
  standalone: true,
  selector: 'app-external-calendar-modal',
  templateUrl: './external-calendar-modal.component.html',
  styleUrls: ['./external-calendar-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ExternalCalendarModalComponent implements OnInit {
  @Output() action = new EventEmitter();

  public safeAreaBottom: number;

  public iframeLink: string;

  constructor(
    private sanitizer: DomSanitizer,
    private modalsSafeAreaService: ModalsSafeAreaService,
  ) {}

  public ngOnInit(): void {
    this.safeAreaBottom = this.modalsSafeAreaService.getSafeAreaBottom();
  }

  protected calendarUrl(): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.iframeLink);
  }
}
