import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { Person } from '../model';

@Injectable({
  providedIn: 'root',
})
export class PersonUpdateService {
  private avatar: BehaviorSubject<Person> = new BehaviorSubject<Person>(new Person());

  constructor() {}

  public getUpdatedPerson(): Observable<Person> {
    return this.avatar.asObservable();
  }

  public setUpdatedPerson(value: Person): void {
    this.avatar.next(value);
  }
}
