export class SharedVideoRequest {
  owner_id: string;
  page: number = 0;
  page_size: number = 20;
}

export class SharedVideoResponse {
  page: number;
  pageSize: number;
  total: number;
  totalPages: number;
  data: SharedVideo;
}

export class SharedVideo {
  id: number;
  title: string;
  description: string;
  playlistUrl: string;
  posterUrl: string;
  duration: number;
  created: string;
}
