import { Component, OnInit } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import { environment } from '../../../../environments/environment';

import { ModalsSafeAreaService } from '../../global-store';
import { ClientService } from '../../service';

import { Advisor } from '../../model';

@Component({
  standalone: true,
  selector: 'app-client-invitation-modal',
  templateUrl: './client-invitation-modal.component.html',
  styleUrls: ['./client-invitation-modal.component.scss'],
  imports: [NgOptimizedImage, FormsModule],
})
export class ClientInvitationModalComponent implements OnInit {
  public safeAreaBottom: number;

  public emails: string = '';
  public validEmails: Array<string> = [];
  public loaders: Array<number> = [];
  public sendingEmails: boolean = false;
  public allEmailsSend: boolean = false;

  public advisorInfo: Advisor = new Advisor();

  constructor(
    protected bsModalRef: BsModalRef,
    private toastr: ToastrService,
    private clientService: ClientService,
    private toasterService: ToastrService,
    private modalsSafeAreaService: ModalsSafeAreaService,
  ) {}

  public ngOnInit(): void {
    this.safeAreaBottom = this.modalsSafeAreaService.getSafeAreaBottom();
  }

  protected inviteClients(): void {
    // preparing emails
    this.sendingEmails = true;
    const emailArray: string[] = this.emails.split(',');

    emailArray.forEach((mail: string): void => {
      // validate emails - filter out
      const email: string = mail.replace(/\s/g, '');
      if (this.validateEmail(email)) {
        this.validEmails.push(email);
        this.loaders.push(0);
      }
    });

    this.sendInvite(this.validEmails);
    this.simulateLoader(this.loaders);
    this.allEmailsSend = true;
  }

  private async simulateLoader(loader: any): Promise<void> {
    for (const i in loader) {
      const timer = (ms) => new Promise((res) => setTimeout(res, ms));

      let index: number = 0;
      while (index <= 100) {
        index += Math.floor(Math.random() * (15 - 1 + 1)) + 1;
        this.loaders[i] = index;

        await timer(16 * this.validEmails.length);
      }
    }
  }

  private sendInvite(email: string[]): void {
    this.clientService.sendInvite(email).subscribe({
      next: (): void => {
        this.toastr.info('Einladung erfolgreich verschickt!');
        this.closeModal();
      },
      error: (error): void => {
        console.log(error);
        this.closeModal();
      },
    });
  }

  protected validateEmail(email: string): boolean {
    const emailCheck: RegExp =
      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
    return emailCheck.test(email);
  }

  protected copyInviteLink(): void {
    navigator.clipboard.writeText(`${environment.appUrl}/register?referral=uid:${this.advisorInfo.uid}`);
    this.toasterService.info('Link in Zwischenablage kopiert');
  }

  protected closeModal(): void {
    this.bsModalRef.hide();
    this.sendingEmails = false;
    this.allEmailsSend = false;
    this.validEmails = [];
    this.loaders = [];
  }
}
