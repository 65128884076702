import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';

@Directive({
  selector: '[passwordValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: PasswordValidatorDirective, multi: true }],
})
export class PasswordValidatorDirective implements Validator {
  public forbiddenName: string = 'bob';

  validate(control: AbstractControl): { [key: string]: any } | null {
    return this.forbiddenName ? this.forbiddenNameValidator(control.value)(control) : null;
  }

  forbiddenNameValidator(nameRe: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let forbidden = false;
      let errorList: any = {};

      if (control.value) {
        let password = control.value;

        forbidden = password.length < 7;
        if (forbidden) {
          errorList.tooShort = { value: password };
        }
      }

      return forbidden ? errorList : null;
    };
  }
}
