import { Observable } from 'rxjs';

export default class OneTimeRequestCache<T> {
  private cache: T | null = null;
  private loadingHandler: ((err: any, value?: T) => void)[] | null = null;

  public request(requestBuilder: () => Observable<T>): Observable<T> {
    if (this.cache) {
      return new Observable<T>((subscriber) => {
        subscriber.next(this.cache!);
        subscriber.complete();
      });
    }

    return new Observable<T>((subscriber) => {
      if (this.loadingHandler) {
        if (this.cache) {
          subscriber.next(this.cache);
          subscriber.complete();
        } else {
          this.loadingHandler!.push((err, value) => {
            if (value) {
              subscriber.next(value);
            } else {
              subscriber.error(err);
            }
            subscriber.complete();
          });
        }
        return;
      }

      this.loadingHandler = [];
      const subscription = requestBuilder().subscribe({
        next: (value) => {
          if (!this.loadingHandler) {
            console.warn('Cache request added multiple values!');
            return;
          }

          this.cache = value;
          for (const handler of this.loadingHandler!) {
            handler(undefined, value);
          }
          this.loadingHandler = null;
          subscriber.next(value);
        },
        error: (err) => {
          if (!this.loadingHandler) {
            console.warn('Cache request added multiple values!');
            return;
          }

          for (const handler of this.loadingHandler!) {
            handler(err);
          }
          this.loadingHandler = null;
          subscriber.error(err);
        },
        complete: () => {
          if (this.loadingHandler) {
            console.warn('Cache request completed without value!');
            this.loadingHandler = null;
          }

          subscriber.complete();
        },
      });
      subscriber.add(() => subscription.unsubscribe());
    });
  }

  public overrideCache(value: T): void {
    this.cache = value;
  }

  public clearCache(): void {
    this.cache = null;
  }
}
