import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

import { AdvisorConsentResponse, UserConsent } from '../model';

@Injectable({
  providedIn: 'root',
})
export class ConsentService {
  constructor(private http: HttpClient) {}

  public getVinlivtMandatoryConsent(): Observable<any> {
    return this.http.get<Array<UserConsent>>(environment.apiEndpoint + '/b2c/consent/user');
  }

  public getAdvisorMandatoryConsent(): Observable<AdvisorConsentResponse> {
    return this.http.get<AdvisorConsentResponse>(environment.apiEndpoint + '/b2c/consent/advisor');
  }

  public consent(id: number): Observable<any> {
    return this.http.get<any>(environment.apiEndpoint + '/b2c/consent/read/' + id);
  }

  public consentByType(consentType: string): Observable<any> {
    return this.http.get<any>(environment.apiEndpoint + '/b2c/consent/type/' + consentType);
  }

  public consentByTypeAndCompany(consentType: string, companyId: number): Observable<any> {
    return this.http.get<any>(`${environment.apiEndpoint}/b2c/consent/type/${consentType}/${companyId}`);
  }

  public acceptVinlivtMandatoryConsents(): Observable<void> {
    return this.http.get<void>(environment.apiEndpoint + '/b2c/consent/acceptmandatory/vinlivt');
  }

  // acceptAdvisorMandatoryConsents(id: number) {
  public acceptAdvisorMandatoryConsents(): Observable<void> {
    // return this.http.get<any>(`${environment.apiEndpoint}/b2c/consent/acceptmandatory/advisor/${id}`).pipe(
    return this.http.get<void>(`${environment.apiEndpoint}/b2c/consent/acceptmandatory/advisor`);
  }
}
