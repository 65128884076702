import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

import { InsuranceProvider, InsuranceCategory } from '../model';

import { InsuranceTypeEnum } from '../enum/insurance-type.enum';

@Injectable({
  providedIn: 'root',
})
export class InsuranceProviderService {
  constructor(private http: HttpClient) {}

  public categories(): Observable<Array<InsuranceCategory>> {
    return this.http.get<Array<InsuranceCategory>>(environment.apiEndpoint + '/b2c/insurances');
  }

  public providers(insuranceTypeEnums: Array<InsuranceTypeEnum>): Observable<Array<InsuranceProvider>> {
    return this.http.get<Array<InsuranceProvider>>(
      environment.apiEndpoint + '/b2c/insurances/providers?types=' + insuranceTypeEnums,
    );
  }
}
