import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

import { UiChangeTriggersService } from './shared/global-store';

interface ChatMessagePushNotificationData {
  readonly type: 'chatMessage';
  readonly target: 'chat';
  readonly chatId: number;
}

interface BankAccountPushNotificationData {
  readonly type: 'openBankingHighTransactionAlarm' | 'openBankingLowBalanceAlarm';
  readonly target: 'bankAccount';
  readonly accountId: number;
}

type PushNotificationData = ChatMessagePushNotificationData | BankAccountPushNotificationData;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    localeService: BsLocaleService,
    private readonly router: Router,
    private readonly uiChangeTriggersService: UiChangeTriggersService,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
    localeService.use('de');

    if ('native' in window) {
      this.loadNativeStyles();
    }
  }

  public ngOnInit(): void {
    this.router.events.subscribe((event): void => {
      if (event instanceof NavigationStart) {
        if (!!event.url && event.url.match(/^\/#/)) {
          this.router.navigate([event.url.replace('/#', '')]);
        }
      }
    });

    this.uiChangeTriggersService.init();

    window.native?.registerPushNotificationHandler((data: PushNotificationData): void => {
      // console.log('Received push notification:', data);
      // alert(data.type);
      // alert(data.target);

      switch (data.type) {
        case 'chatMessage':
          this.router.navigate(['/vinhub']);
          break;

        case 'openBankingHighTransactionAlarm':
          this.router.navigate(['/bank-card-manager', data.accountId]);
          break;

        case 'openBankingLowBalanceAlarm':
          this.router.navigate(['/bank-card-manager', data.accountId]);
          break;

        default:
          return;
      }
    });
  }

  private loadNativeStyles(): void {
    const head = this.document.getElementsByTagName('head')[0];

    const style = this.document.createElement('link');
    style.id = 'client-theme';
    style.rel = 'stylesheet';
    style.href = `assets/scss/native/${window.native.operatingSystem}.css`;

    head.appendChild(style);
  }
}
