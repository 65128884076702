import { Component, EventEmitter, Input, Output } from '@angular/core';

import { PersonProfile } from '../../../model';

import { RelationshipStatusEnum } from '../../../enum';
import { SharedModule } from '../../../shared.module';
import { NgClass } from '@angular/common';

@Component({
  standalone: true,
  selector: 'app-onboarding-relationship-step',
  templateUrl: 'onboarding-relationship-step.component.html',
  styleUrls: ['onboarding-relationship-step.component.scss'],
  imports: [SharedModule, NgClass],
})
export class OnboardingRelationshipStepComponent {
  @Input() personProfile: PersonProfile;

  @Output() triggerNextStep = new EventEmitter();
  @Output() triggerPreviousStep = new EventEmitter();

  protected relationshipStatus = RelationshipStatusEnum;
  protected enumObject: string[] = Object.keys(RelationshipStatusEnum);

  constructor() {}

  protected setValue(key: any): void {
    this.personProfile.relationshipStatus = key;
    this.triggerNextStep.emit();
  }
}
