import { Component, Inject, NgModule, OnInit } from '@angular/core';
import { ActivatedRoute, ExtraOptions, Router, RouterModule, Routes } from '@angular/router';
import { AUTH_SERVICE_TOKEN, AuthService } from './shared/service';

@Component({template: ''})
class RegisterComponent implements OnInit {
  public constructor(@Inject(AUTH_SERVICE_TOKEN) private readonly authService: AuthService,
                     private readonly router: Router,
                     private readonly route: ActivatedRoute) {
  }

  public ngOnInit(): void {
    if (this.authService.loggedIn) {
      this.router.navigateByUrl('/dashboard', {replaceUrl: true});
    } else {
      const {email, referral} = this.route.snapshot.queryParams;
      this.authService.register(email, referral);
    }
  }
}

const staticRoutes: Routes = [
  {
    path: 'consent/:id',
    loadChildren: () => import('./pages/consent/consent.module').then((m) => m.ConsentModule),
  },
  {
    path: 'register',
    component: RegisterComponent,
  },
  {
    path: '',
    loadChildren: () => import('./layout/app-layout.module').then((m) => m.LayoutModule),
    data: {animation: 'Two'},
  },
  // TODO: add ErrorPage for handle 400/500 errors
  {path: '**', redirectTo: '', data: {animation: 'One'}},
];

const routerConfig: ExtraOptions = {
  useHash: false,
  enableTracing: false,
  scrollPositionRestoration: 'top',
};

@NgModule({
  imports: [RouterModule.forRoot(staticRoutes, routerConfig)],
  exports: [RouterModule],
  declarations: [RegisterComponent],
})
export class AppRoutesModule {
}
