import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ContractUploadService {
  constructor(private http: HttpClient) {}

  // drag and drop
  public uploadFiles(files: File[], contractUid: string): Observable<any> {
    const formdata: FormData = new FormData();
    Array.from(files).forEach((file) => {
      if (file.size / 1024 / 1024 <= 10.0) {
        formdata.append('file', file, file.name);
      }
    });

    return this.http.post<any>(`${environment.apiEndpoint}/b2c/contractsmanager/uploadfiles/${contractUid}`, formdata);
  }

  // drag and drop
  public uploadFilesForContract(files: File[], contractUid: string | number, attContractType: string): Observable<any> {
    const formdata: FormData = new FormData();
    Array.from(files).forEach((file) => {
      if (file.size / 1024 / 1024 <= 10.0) {
        formdata.append('file', file, file.name);
      }
    });

    return this.http.post<any>(
      `${environment.apiEndpoint}/b2c/contractsmanager/uploadfiles/${contractUid}/${attContractType}`,
      formdata,
    );
  }

  public delete(id: number): Observable<void> {
    return this.http.delete<void>(`${environment.apiEndpoint}/b2c/contractsmanager/uploadfiles/delete/${id}`);
  }
}
