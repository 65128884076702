import {
  RelationshipStatusEnum,
  ProfessionStatusEnum,
  HousingSituationStatusEnum,
  PropertyOwnershipStatusEnum,
  ProvisionTargetEnum,
  HealthInsuranceStatusEnum,
  MobilityStatusEnum,
  MiscStatusEnum,
  PetStatusEnum,
} from '../enum';

export class FullPersonProfile {
  fullyAnswered: false;
  personProfile: PersonProfile;
  reloggedIn: false;
}

export class PersonProfile {
  id: number;
  relationshipStatus: RelationshipStatusEnum;
  professionStatus: ProfessionStatusEnum;
  healthInsuranceStatus: HealthInsuranceStatusEnum;
  childrenAmount: number;
  applicableChildrenSupport: number = 0;
  accountableChildrenAmount: number = 0;
  mobilityStatus: Array<MobilityStatusEnum> = [];
  misc: Array<MiscStatusEnum> = [];
  pets: Array<PetStatusEnum> = [];
  housingSituation: HousingSituationStatusEnum;
  propertyOwnership: Array<PropertyOwnershipStatusEnum> = [];
  provisionTarget: Array<ProvisionTargetEnum> = [];
  rated: boolean;
}

export class OnBoardingCheckInfo {
  completedPersonalData: boolean;
  fullyAnsweredFinancialQuestions: boolean;
}
