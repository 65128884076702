import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

export type EffectiveTheme = NativeBridge.ThemeVariant;
export type Theme = NativeBridge.ThemeSetting;

@Injectable({
  providedIn: 'root',
})
export class UiChangeTriggersService {
  public readonly effectiveThemeChanged = new Subject<EffectiveTheme>();
  private _themeVariant: EffectiveTheme = 'light';
  private blurredState: string = 'blurredState';
  private darkThemeMatch: MediaQueryList | null = null;
  private boxShadow: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private _themeSetting: Theme = 'auto';

  public get themeSetting(): Theme {
    return window.native?.themeSetting ?? this._themeSetting;
  }

  public set themeSetting(value: Theme) {
    if ('native' in window) {
      window.native.themeSetting = value;
    } else {
      this._themeSetting = value;
      localStorage.setItem('theme', value);
      this.updateTheme();
    }
  }

  public get theme(): EffectiveTheme {
    return window.native?.themeVariant ?? this._themeVariant;
  }

  public getBlurState(): string {
    return localStorage.getItem(this.blurredState) || 'none';
  }

  public setBlurState(state: string): void {
    localStorage.setItem(this.blurredState, state);
  }

  public init(): void {
    if ('native' in window) {
      window.addEventListener('themeVariantChanged', (event) => this.updateEffectiveTheme((event as CustomEvent).detail));
      this.updateEffectiveTheme(window.native.themeVariant);
    } else {
      const themeSetting = localStorage.getItem('theme');

      if (themeSetting === 'light' || themeSetting === 'dark' || themeSetting === 'auto') {
        this._themeSetting = themeSetting;
      }

      this.updateTheme();
    }
  }

  // show or hide box shadow from header when date-picker appear
  public getUpdatedBoxShadow(): Observable<boolean> {
    return this.boxShadow.asObservable();
  }

  public setUpdatedBoxShadow(value: boolean): void {
    this.boxShadow.next(value);
  }

  private updateTheme(): void {
    if (this._themeSetting === 'auto') {
      if (!this.darkThemeMatch) {
        this.darkThemeMatch = window.matchMedia('(prefers-color-scheme: dark)');
        this.darkThemeMatch.addEventListener('change', (event) =>
          this.updateEffectiveTheme(event.matches ? 'dark' : 'light'));
      }

      this.updateEffectiveTheme(this.darkThemeMatch.matches ? 'dark' : 'light');
    } else {
      if (this.darkThemeMatch) {
        this.darkThemeMatch.removeAllListeners('change');
        this.darkThemeMatch = null;
      }

      this.updateEffectiveTheme(this._themeSetting);
    }
  }

  private updateEffectiveTheme(value: EffectiveTheme): void {
    this._themeVariant = value;
    const body: HTMLElement = document.body;

    switch (value) {
      case 'light':
        body.classList.add('light-theme');
        body.classList.remove('dark-theme');
        break;
      case 'dark':
        body.classList.remove('light-theme');
        body.classList.add('dark-theme');
        break;
    }

    if ('native' in window) {
      // This defines the color of the status bar.
      // It must be the opposite of the theme so that the status bar is readable.
      // When the application is in light mode, the status bar is dark and vice versa.
      window.native.setSystemUIOverlayStyle(value === 'light' ? 'dark' : 'light');
    }

    this.effectiveThemeChanged.next(value);
  }
}
