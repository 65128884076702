<div class="contract-pending-view-container">
  <div class="row">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-center">
        <img
          src="https://static-assets.vinlivt.de/icons/premium/purple/320/Clock 2.png"
          width="120"
          style="min-width: 120px"
          alt="clock image"
        />
      </div>
    </div>
  </div>

  <div class="row mt-20">
    <div class="col-12 text-center">
      <div class="font-size-h4 theme-color">Warten auf Bestätigung</div>
    </div>
  </div>

  <div class="row my-50">
    <div class="col-12 text-center">
      <div class="font-size-h5 theme-secondary-color">
        @if (brokerMandate.status === 'PENDING') {
          <span>
            Dein Experte checkt gerade dein Maklermandat. Du erhältst eine Benachrichtigung wenn die Prüfung erfolgt
            ist.
          </span>
        }

        @if (brokerMandate.status === 'REVOCATION_REQUESTED') {
          <span>
            Dein Experte hat deinen Widerruf erhalten. Du erh&auml;ltst schnellstm&ouml;glich R&uuml;ckmeldung dazu.
          </span>
        }
      </div>
    </div>
  </div>

  <!--  <div class="row mt-10">-->
  <!--    @for (type of types; track type.type) {-->
  <!--      <div class="col-8 d-flex align-items-center">-->
  <!--        <i class="bi bi-filetype-pdf theme-color mr-3 text-20"></i>-->

  <!--        <div class="theme-color">{{ type.name }}</div>-->
  <!--      </div>-->

  <!--      <div class="col-4 text-right">-->
  <!--        <a class="text-purple mr-3" href="javascript:void(0)" (click)="downloadPDF.emit(type.type)">-->
  <!--          <i class="bi bi-download theme-color text-20"></i>-->
  <!--        </a>-->

  <!--        <a class="text-purple" href="javascript:void(0)" (click)="showPreview.emit(type.type)">-->
  <!--          <i class="bi bi-eye theme-color text-20"></i>-->
  <!--        </a>-->
  <!--      </div>-->
  <!--    }-->
  <!--  </div>-->

  <div class="row mt-50">
    <div class="col-12 text-center">
      <button class="btn btn-purple" (click)="closeModal.emit()">Schließen</button>
    </div>
  </div>
</div>
