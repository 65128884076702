<div class="broker-pin-modal-container" [style.padding-bottom.px]="safeAreaBottom">
  <div class="d-flex d-md-none justify-content-center pt-10" (click)="action.emit(null)">
    <img src="https://static-assets.vinlivt.de/icons/misc/mobile-gray-line.png" alt="line" />
  </div>

  <div class="modal-body w-100 p-20">
    <div class="row mx-0 img-center">
      <div class="col-md-12 px-0">
        <form #creditApplicationForm="ngForm" novalidate>
          <div class="row px-5">
            <div class="col-md-12">
              <div
                class="justify-content-center text-center flex-column align-items-center"
                [ngClass]="{ validation: !isPinVerified, 'valid-pin': isPinVerified }"
              >
                @if (isLoading) {
                  <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Wird geladen...</span>
                  </div>
                } @else {
                  @if (isPinVerified) {
                    <img
                      src="https://static-assets.vinlivt.de/icons/premium/real/320x320/Password%201.png"
                      alt=""
                      height="150"
                    />

                    <span>
                      <h3 class="section-title mb-5">Zugangscode akzeptiert</h3>
                    </span>
                  } @else {
                    <img
                      src="https://static-assets.vinlivt.de/icons/premium/purple/320/Password 1.png"
                      alt=""
                      height="150"
                    />

                    <span>
                      <h3 class="section-title mb-5">Hereinspaziert</h3>
                      <p class="theme-secondary-color">
                        Bitte gib deinen Zugangscode von
                        <br />
                        deinem Finanzberater ein

                        <i
                          class="bi bi-info-circle text-purple pointer-cursor ml-1"
                          tooltip="In der Einladung von deinem Finanzexperten, ist ein 6-stelliger Zugangscode enthalten."
                          placement="left"
                        ></i>
                      </p>
                    </span>
                  }
                }
              </div>

              @if (isLoading) {
                <!-- Loading state ? -->
              } @else {
                @if (!isPinVerified) {
                  <div class="form-group row justify-content-center text-center">
                    <div class="col-12 col-md-10">
                      <div class="row input-digit-group justify-content-center">
                        <div class="col-2">
                          <input
                            name="tan1"
                            #tan1
                            (paste)="onPaste($event)"
                            (keyup)="isNumber($event, tan1)"
                            autofocus
                            min="0"
                            max="9"
                            maxlength="1"
                            inputmode="numeric"
                            autocomplete="off"
                            type="tel"
                            placeholder="#"
                            class="form-control"
                            data-testid="tan-input-tan0"
                            value=""
                          />
                        </div>
                        <div class="col-2">
                          <input
                            name="tan2"
                            #tan2
                            (paste)="onPaste($event)"
                            (keyup)="isNumber($event, tan2)"
                            (keydown.backspace)="moveToPreviousTan(1)"
                            min="0"
                            max="9"
                            maxlength="1"
                            inputmode="numeric"
                            autocomplete="off"
                            type="tel"
                            placeholder="#"
                            class="form-control"
                            data-testid="tan-input-tan0"
                            value=""
                          />
                        </div>
                        <div class="col-2">
                          <input
                            name="tan3"
                            #tan3
                            (paste)="onPaste($event)"
                            (keyup)="isNumber($event, tan3)"
                            (keydown.backspace)="moveToPreviousTan(2)"
                            min="0"
                            max="9"
                            maxlength="1"
                            inputmode="numeric"
                            autocomplete="off"
                            type="tel"
                            placeholder="#"
                            class="form-control"
                            data-testid="tan-input-tan0"
                            value=""
                          />
                        </div>
                        <div class="col-2">
                          <input
                            name="tan4"
                            #tan4
                            (paste)="onPaste($event)"
                            (keyup)="isNumber($event, tan4)"
                            (keydown.backspace)="moveToPreviousTan(3)"
                            min="0"
                            max="9"
                            maxlength="1"
                            inputmode="numeric"
                            autocomplete="off"
                            type="tel"
                            placeholder="#"
                            class="form-control"
                            data-testid="tan-input-tan0"
                            value=""
                          />
                        </div>
                        <div class="col-2">
                          <input
                            name="tan5"
                            #tan5
                            (paste)="onPaste($event)"
                            (keyup)="isNumber($event, tan5)"
                            (keydown.backspace)="moveToPreviousTan(4)"
                            min="0"
                            max="9"
                            maxlength="1"
                            inputmode="numeric"
                            autocomplete="off"
                            type="tel"
                            placeholder="#"
                            class="form-control"
                            data-testid="tan-input-tan0"
                            value=""
                          />
                        </div>
                        <div class="col-2">
                          <input
                            name="tan6"
                            #tan6
                            (paste)="onPaste($event)"
                            (keyup)="isNumber($event, tan6)"
                            (keydown.backspace)="moveToPreviousTan(5)"
                            min="0"
                            max="9"
                            maxlength="1"
                            inputmode="numeric"
                            autocomplete="off"
                            type="tel"
                            placeholder="#"
                            class="form-control"
                            data-testid="tan-input-tan0"
                            value=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <!--                  <div class="form-group row justify-content-center text-center mt-20">-->
                  <!--                    <p class="mt-20 mb-0 text-12 theme-color">-->
                  <!--                      Woher bekomme ich einen-->
                  <!--                      <a class="text-purple" href="https://www.vinlivt.app" target="_blank">Zugangscode</a>?-->
                  <!--                    </p>-->
                  <!--                  </div>-->
                }

                @if (invalidPinMessage) {
                  <div class="text-danger text-center">Falscher Zugangscode!</div>
                }
              }
            </div>

            @if (isPinVerified) {
              <div class="col-12 mt-15">
                <button
                  class="btn btn-block btn-rounded btn-purple"
                  id="finish-btn"
                  (click)="action.emit(assignedAdvisor)"
                >
                  Fertig
                </button>
              </div>
            }

            <div class="col-12 mt-15">
              <button class="btn btn-block btn-outline-primary btn-rounded" (click)="action.emit(null)">
                Ausloggen
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
