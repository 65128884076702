<div class="media-preview-mobile-modal-container" [style.padding-bottom.px]="getSafeAreaBottom()">
  <div class="modal-body" [style.padding-top.px]="safeAreaTop || 0" [style.padding-bottom.px]="safeAreaBottom || 0">
    <div class="d-flex align-items-center justify-content-end top-bar pr-20">
      <i class="bi bi-x-lg text-20 text-white mt-2 pointer-cursor" (click)="modalRef.hide()"></i>
    </div>

    <div class="col-12 px-0 d-flex justify-content-center align-items-center flex-grow-1 position-relative">
      @if (type !== 'OTHER') {
        @if (downloadInProgress) {
          <ngx-skeleton-loader appearance="custom-content" [theme]="{ height: '500px' }"></ngx-skeleton-loader>
        } @else if (type === 'IMAGE') {
          <img class="w-100" [src]="blobUrl" alt="preview" />
        } @else if (type === 'PDF') {
          <pdf-viewer
            [src]="fileBytes"
            [render-text]="false"
            [original-size]="false"
            class="pdf-container"
            [class.loading]="!pdfLoaded"
            (page-rendered)="pdfLoaded = true"
          ></pdf-viewer>
          @if (!pdfLoaded) {
            <ngx-skeleton-loader
              class="pdf-container-skeleton"
              appearance="custom-content"
              [theme]="{ height: '500px' }"
            ></ngx-skeleton-loader>
          }
        }
      }
    </div>

    <div class="bottom-bar">
      <div class="row p-20">
        <div class="col-10">
          <div class="d-flex flex-column">
            <div class="parent-truncate">
              @if (type === 'PDF') {
                <div class="text-white child-truncate">{{ filename ?? '' }}</div>
              }
            </div>

            <div class="theme-secondary-color">
              <span class="mr-10">{{ type ?? '' }}</span>
              <span>{{ sizeFormatted ?? '' }}</span>
            </div>
          </div>
        </div>

        <div class="col-2 d-flex align-items-center justify-content-end">
          <button class="btn" (click)="download()">
            <i class="bi bi-download text-white text-20"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
