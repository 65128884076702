<div
  class="unread-messages-widget-container pointer-cursor px-15"
  [ngClass]="{
    'br-16 py-2 mb-15': innerWidth < 950,
    'btn btn-rounded btn-noborder py-1 hover-effect': innerWidth > 950,
    'd-none': unreadMessages === 0 || unreadMessages === null
  }"
  routerLink="/vinhub"
>
  <!-- Mobile view -->
  <div class="row d-md-none">
    <div class="col-10 d-flex align-items-center">
      <img
        class="img-plain"
        ngSrc="https://static-assets.vinlivt.de/icons/premium/real/320x320/orange-jet.png"
        width="50"
        height="50"
        alt="jet"
      />

      <div class="text-16 font-w700 ml-3">Ungelesene Nachrichten</div>
    </div>

    <div class="col-2 d-flex align-items-center justify-content-end">
      <div class="btn btn-circle btn-orange d-flex align-items-center justify-content-center text-16">
        +{{ unreadMessages }}
      </div>
    </div>
  </div>

  <!-- Desktop view -->
  <div class="row d-none d-md-block">
    <div class="col-12 d-flex align-items-center">
      <img
        ngSrc="https://static-assets.vinlivt.de/icons/premium/real/320x320/orange-jet.png"
        width="32"
        height="32"
        alt="jet"
      />

      <div class="ml-3 font-w400">{{ unreadMessages }} Neue Nachrichten</div>
    </div>
  </div>
</div>
