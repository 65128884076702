import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

import { BudgetCreditResponse } from '../model';

@Injectable({
  providedIn: 'root',
})
export class BudgetService {
  constructor(private http: HttpClient) {}

  public creditPossibility(): Observable<BudgetCreditResponse> {
    return this.http.get<BudgetCreditResponse>(environment.apiEndpoint + '/b2c/budget/credit');
  }
}
