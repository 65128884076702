import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, retry } from 'rxjs';

import { environment } from '../../../environments/environment';

import { BrokerMandateData } from '../model';

@Injectable({
  providedIn: 'root',
})
export class BrokerMandateService {
  constructor(private http: HttpClient) {}

  public init(): Observable<BrokerMandateData> {
    return this.http.get<BrokerMandateData>(environment.apiEndpoint + '/b2c/brokermandate');
  }

  public pdf(type: string): Observable<any> {
    const url: string = environment.apiEndpoint + '/b2c/brokermandate/pdf/' + type;

    const authHeader = new HttpHeaders();
    authHeader.set('Accept', 'application/pdf');

    return this.http
      .get<any>(url, {
        responseType: 'blob' as 'json',
        headers: authHeader,
      })
      .pipe(retry(1));
  }

  public getPdf(type: string): Observable<Blob> {
    return this.http.get(`${environment.apiEndpoint}/b2c/brokermandate/pdf/${type}`, { responseType: 'blob' });
  }
}
