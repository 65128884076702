import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { SharedModule } from '../shared/shared.module';

import { UserSidebarComponent } from './user-sidebar/user-sidebar.component';
import { SidebarComponent } from './sidebar/app-sidebar.component';
import { UserSidebarModalComponent } from './user-sidebar-modal/user-sidebar-modal.component';
import { HeaderComponent } from './header/app-header.component';
import { LayoutComponent } from './app-layout.component';
import { MobileBottomNavigationComponent } from './mobile-bottom-navigation/mobile-bottom-navigation.component';

import { ClientInvitationBannerComponent } from '../shared/components/client-invitation-banner/client-invitation-banner.component';

import { ROUTES } from './layout.routes';
import { AdvisorMicroCardComponent } from '../shared/components/advisor-micro-card/advisor-micro-card.component';
import { UnreadMessagesWidgetComponent } from '../shared/components/unread-messages-widget/unread-messages-widget.component';

@NgModule({
  declarations: [
    LayoutComponent,
    HeaderComponent,
    SidebarComponent,
    UserSidebarComponent,
    UserSidebarModalComponent,
    MobileBottomNavigationComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ROUTES,
    NgOptimizedImage,
    NgxSkeletonLoaderModule,
    ClientInvitationBannerComponent,
    AdvisorMicroCardComponent,
    UnreadMessagesWidgetComponent,
  ],
  exports: [LayoutComponent],
})
export class LayoutModule {}
