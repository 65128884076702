import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgClass } from '@angular/common';

import { SharedModule } from '../../../shared.module';

import { PersonProfile } from '../../../model';

import { HealthInsuranceStatusEnum } from '../../../enum';

@Component({
  standalone: true,
  selector: 'app-onboarding-health-insurance-step',
  templateUrl: 'onboarding-health-insurance-step.component.html',
  styleUrls: ['onboarding-health-insurance-step.component.scss'],
  imports: [SharedModule, NgClass],
})
export class OnboardingHealthInsuranceStepComponent {
  @Input() personProfile: PersonProfile;

  @Output() triggerNextStep = new EventEmitter();
  @Output() triggerPreviousStep = new EventEmitter();

  protected healthInsuranceStatusEnum = HealthInsuranceStatusEnum;
  protected enumObject = Object.keys(HealthInsuranceStatusEnum);

  constructor() {}

  protected setValue(key: any): void {
    this.personProfile.healthInsuranceStatus = key;
    this.triggerNextStep.emit();
  }
}
