<div class="client-invitation-modal-container" [style.padding-bottom.px]="safeAreaBottom">
  <div class="modal-body pt-0 px-0 overflow-hidden">
    <div class="top-content mb-30 p-3">
      <div class="row mb-15">
        <div class="col-6">
          <div
            class="bg-white d-flex align-items-center justify-content-center"
            style="border-radius: 12px; width: 40px; height: 40px"
          >
            <img width="30" height="30" src="https://static-assets.vinlivt.de/logo/icon/app_icon.svg" alt="lgoo-icon" />
          </div>
        </div>

        <div class="col-6 text-right">
          <i class="bi bi-x-lg text-white" (click)="bsModalRef.hide()"></i>
        </div>
      </div>

      <div class="row mb-15">
        <div class="col-12 mb-15 text-center">
          <img
            class="advisor-img"
            style="border-radius: 50%"
            src="{{ advisorInfo?.profilePictureUrl }}"
            alt="advisor"
          />
        </div>

        <div class="col-12 text-center">
          <div class="text-16 text-white">{{ advisorInfo?.firstName + ' ' + advisorInfo?.lastName }}</div>
        </div>

        <div class="col-12 text-center">
          <div class="text-12 text-white">Finanzexperte</div>
        </div>
      </div>
    </div>

    <div class="row px-3">
      <!--      <div class="col-12 col-md-4 col-lg-3 img-background pl-20 pt-20">-->
      <!--        <img ngSrc="https://static-assets.vinlivt.de/logo/icon/splash-logo-40x40.svg" width="40" height="40" alt="v" />-->
      <!--      </div>-->

      <div class="col-12 layout-padding">
        <div class="row">
          <div class="col-12 mb-15">
            <div class="section-title">Berater Freunden empfehlen</div>
          </div>

          <div class="col-12">
            <div class="theme-secondary-color">
              Um deinen persönlichen Berater weiterzuempfehlen, bitte gib die E-Mail von deinem Freund ein und
              anschließend wird eine Einladung in deinem Namen versendet. Oder kopiere den Einladungslink und versende
              ihn per Whatsapp.
            </div>
          </div>

          <div class="col-12 mt-30">
            <div class="row">
              <div class="col-12">
                <label class="theme-color" for="emails">E-Mail deines Freundes</label>

                <input
                  class="form-control form-control-lg"
                  [(ngModel)]="emails"
                  id="emails"
                  name="emails"
                  placeholder="E-Mail"
                  required
                />
              </div>
            </div>
          </div>

          <div class="col-12 mt-15 mb-15">
            <button class="btn btn-rounded purple-outline-btn" (click)="copyInviteLink()">
              <i class="bi bi-copy mr-1"></i>
              <span>Einladungslink</span>
            </button>
          </div>

          <div class="col-12">
            <div class="row">
              <div class="col-5">
                <button class="btn px-0 text-purple" (click)="closeModal()">Abbrechen</button>
              </div>

              <div class="col-7 text-right">
                <button [disabled]="!validateEmail(emails)" (click)="inviteClients()" class="btn btn-purple">
                  <i class="fa fa-send mr-1"></i>
                  <span>Jetzt einladen</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
