<div class="agent-modal-container" [style.padding-bottom.px]="safeAreaBottom">
  <div class="d-flex d-md-none justify-content-center pt-10" (click)="closeAdvisorModal.emit()">
    <img src="https://static-assets.vinlivt.de/icons/misc/mobile-gray-line.png" alt="line" />
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <div class="d-flex align-items-center">
          @if (advisor?.profilePictureUrl) {
            <img class="advisor-img mr-10" [src]="advisor.profilePictureUrl" alt="advisor image" />
          } @else {
            <img
              class="advisor-img mr-10"
              src="https://static-assets.vinlivt.de/img/misc/fox.png"
              alt="advisor image"
            />
          }

          <div class="d-flex flex-column">
            <div class="text-16 theme-color">{{ advisor?.firstName }} {{ advisor?.lastName }}</div>

            <div class="theme-secondary-color text-12">
              Hast du Fragen zu deinen Finanzen? Kontaktiere mich jederzeit, ich helfe dir gerne weiter.
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-30">
      <div class="col-6 pr-2">
        <a
          class="btn btn-action-secondary btn-rounded d-flex align-items-center justify-content-center w-100 mb-15"
          routerLink="/vinhub"
          (click)="closeAdvisorModal.emit()"
        >
          <i class="bi bi-chat-dots text-20"></i>

          <span class="ml-2">Chats</span>
        </a>
      </div>

      <div class="col-6 d-flex flex-column align-items-center pl-2">
        <a
          class="btn btn-action-secondary btn-rounded d-flex align-items-center justify-content-center w-100 mb-15"
          (click)="openPhoneOptions()"
        >
          <i class="bi bi-phone text-20"></i>

          <span class="ml-2">Anrufen</span>
        </a>
      </div>

      <div class="col-6 pr-2">
        <a
          class="btn btn-action-secondary btn-rounded d-flex align-items-center justify-content-center w-100 mb-15"
          href="mailto:{{ advisor?.email }}"
        >
          <i class="bi bi-envelope text-20"></i>

          <span class="ml-2">E-Mail</span>
        </a>
      </div>

      @if (advisor?.calendarLink) {
        <div class="col-6 d-flex flex-column align-items-center pl-2">
          <a
            [href]="advisor.calendarLink"
            target="_blank"
            class="btn btn-action-secondary btn-rounded d-flex align-items-center justify-content-center w-100 mb-15"
          >
            <i class="bi bi-calendar-check text-20"></i>

            <span class="ml-2">Termin</span>
          </a>
        </div>
      }

      @if (
        mandateApproval?.status === 'OPEN' ||
        mandateApproval?.status?.startsWith('REJECTED') ||
        mandateApproval?.status === 'REVOCATION_CONFIRMED'
      ) {
        <div [ngClass]="{ 'col-12': !advisor?.meetingLink, 'col-6 px-2': advisor.meetingLink }">
          <a
            class="btn btn-action-secondary btn-rounded d-flex align-items-center justify-content-center w-100 mb-15"
            (click)="openBrokerContractModal()"
          >
            <i class="bi bi-shield-check text-20"></i>

            <span class="ml-2">Mandat</span>
          </a>
        </div>
      }

      @if (
        mandateApproval?.status === 'PENDING' ||
        mandateApproval?.status === 'EXIST_ON_POOL_SITE' ||
        mandateApproval?.status === 'APPROVED' ||
        mandateApproval?.status === 'REVOCATION_REQUESTED'
      ) {
        <div [ngClass]="{ 'col-12': !advisor?.meetingLink, 'col-6 px-2': advisor.meetingLink }">
          <a
            class="btn btn-action-secondary btn-rounded d-flex align-items-center justify-content-center w-100 mb-15"
            (click)="openBrokerContractModal()"
          >
            @if (mandateApproval?.status === 'REVOCATION_REQUESTED') {
              <i class="bi bi-clock text-20"></i>
            }

            @if (mandateApproval?.status !== 'REVOCATION_REQUESTED') {
              <i class="bi bi-file-earmark-arrow-down text-20"></i>
            }

            <span class="ml-2">Mandat</span>
          </a>
        </div>
      }

      @if (advisor?.meetingLink) {
        <div class="col-6 d-flex flex-column align-items-center pl-2">
          <a
            [href]="advisor.meetingLink"
            target="_blank"
            class="btn btn-action-secondary btn-rounded d-flex align-items-center justify-content-center w-100 mb-15"
          >
            <i class="bi bi-camera-video text-20"></i>

            <span class="ml-2">Meeting</span>
          </a>
        </div>
      }
    </div>
  </div>
</div>
