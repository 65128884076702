// modules
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';

// directives
import {
  ActiveStepDirective,
  AutofocusDirective,
  DragAndDropDirective,
  PasswordValidatorDirective,
  TextareaAutosizeDirective,
} from './directive';

// services
import { UiChangeTriggersService } from './global-store';

// guards
import { AuthInterceptor } from './interceptor/auth.interceptor';

// pipes
import {
  EnumConvertedToArrayPipe,
  EnumToArrayPipe,
  GroupByPipe,
  ModalSafeAreaPipe,
  SafeSrcPipe,
  TranslatePropertyTypePipe,
  TruncatePipe,
} from './pipe';
import { authServiceProvider, SeoService } from './service';

@NgModule({
  declarations: [
    TranslatePropertyTypePipe,
    PasswordValidatorDirective,
    DragAndDropDirective,
    AutofocusDirective,
    ActiveStepDirective,
    TextareaAutosizeDirective,
    EnumToArrayPipe,
    TruncatePipe,
    ModalSafeAreaPipe,
    EnumConvertedToArrayPipe,
    GroupByPipe,
    SafeSrcPipe,
  ],
  exports: [
    TranslatePropertyTypePipe,
    PasswordValidatorDirective,
    DragAndDropDirective,
    AutofocusDirective,
    ActiveStepDirective,
    TextareaAutosizeDirective,
    EnumToArrayPipe,
    TruncatePipe,
    ModalSafeAreaPipe,
    EnumConvertedToArrayPipe,
    GroupByPipe,
    SafeSrcPipe,
  ],
})
export class SharedModule {
  public static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        SeoService,
        UiChangeTriggersService,
        authServiceProvider,
        {
          provide: HTTP_INTERCEPTORS,
          multi: true,
          useClass: AuthInterceptor,
        },
      ],
    };
  }
}
