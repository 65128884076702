<div class="modal-header" [style.padding-bottom.px]="safeAreaBottom">
  <div class="theme-color" [innerHTML]="title"></div>
</div>

<div class="modal-body">
  <div class="theme-color" [innerHTML]="description"></div>
</div>
<div class="modal-footer">
  <button class="btn btn-purple d-flex justify-content-end" (click)="action.emit(true)">Schlie&szlig;en</button>
</div>
