import { NgClass } from '@angular/common';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import * as $moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, tap } from 'rxjs';

import { ContractOverview, MandateApproval, MandatePersonData } from '../../model';
import { BrokerMandateApprovalService, BrokerMandateService } from '../../service';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { MediaPreviewBaseModalComponent } from '../media-preview-base-modal/media-preview-base-modal.component';

import { MediaPreviewDesktopModalComponent } from '../media-preview-desktop-modal/media-preview-desktop-modal.component';
import { MediaPreviewMobileModalComponent } from '../media-preview-mobile-modal/media-preview-mobile-modal.component';
import { ContractApprovedViewComponent } from './contract-approved-view/contract-approved-view.component';
import { ContractEndStepComponent } from './contract-end-step/contract-end-step.component';
import { ContractPendingViewComponent } from './contract-pending-view/contract-pending-view.component';
import { ContractStartStepComponent } from './contract-start-step/contract-start-step.component';
import { DataVerificationStepComponent } from './data-verification-step/data-verification-step.component';
import { ModalsSafeAreaService } from '../../global-store';

class PdfDocument {
  public readonly url: string;

  public constructor(private readonly blob: Blob) {
    this.url = URL.createObjectURL(blob);
  }

  public destroy(): void {
    URL.revokeObjectURL(this.url);
  }
}

type PdfType = 'MANDATE' | 'POWER_OF_ATTORNEY' | 'PRIVACY_CONSENT';
const pdfTypes: PdfType[] = ['MANDATE', 'POWER_OF_ATTORNEY', 'PRIVACY_CONSENT'];

@Component({
  standalone: true,
  selector: 'app-broker-contract-contract',
  templateUrl: 'broker-contract-modal.component.html',
  styleUrls: ['broker-contract-modal.component.scss'],
  imports: [
    NgClass,
    ContractStartStepComponent,
    DataVerificationStepComponent,
    ContractEndStepComponent,
    ContractPendingViewComponent,
    ContractApprovedViewComponent,
  ],
})
export class BrokerContractModalComponent implements OnInit, OnDestroy {
  @Output() finishBrokerContract = new EventEmitter();

  public safeAreaBottom: number;

  public moment = $moment;
  public brokerMandate: MandateApproval = new MandateApproval();
  public currentStep: number = 1;
  public person: MandatePersonData = new MandatePersonData();

  public insuranceContracts: ContractOverview[] = [];

  public stepThreeTitle: string = 'Unterschreiben';

  private readonly documents: Partial<Record<PdfType, PdfDocument>> = {};

  constructor(
    public modalRef: BsModalRef,
    private brokerMandateApprovalService: BrokerMandateApprovalService,
    private toasterService: ToastrService,
    private modalService: BsModalService,
    private readonly brokerMandateService: BrokerMandateService,
    private modalsSafeAreaService: ModalsSafeAreaService,
  ) {}

  public ngOnInit(): void {
    this.safeAreaBottom = this.modalsSafeAreaService.getSafeAreaBottom();

    this.getServices();

    forkJoin(
      pdfTypes.map((type: PdfType) =>
        this.brokerMandateService
          .getPdf(type)
          .pipe(tap((blob: Blob) => (this.documents[type] = new PdfDocument(blob)))),
      ),
    ).subscribe();
  }

  public ngOnDestroy(): void {
    Object.values(this.documents).forEach((document: PdfDocument) => document.destroy());
  }

  protected download(type: PdfType): void {
    const pdfDocument: PdfDocument = this.documents[type];
    if (!pdfDocument) {
      return;
    }

    if ('native' in window) {
      window.native.shareFile(pdfDocument.url);
    } else {
      const anchor: HTMLAnchorElement = document.createElement('a');
      anchor.href = pdfDocument.url;
      anchor.download = type.toLowerCase() + '.pdf';
      document.body.append(anchor);
      anchor.click();
      anchor.remove();
    }
  }

  protected preview(type: PdfType): void {
    const pdfDocument: PdfDocument = this.documents[type];
    if (!pdfDocument) {
      return;
    }

    const mediaPreviewModalRef = this.modalService.show<MediaPreviewBaseModalComponent>(
      window.innerWidth > 950 ? MediaPreviewDesktopModalComponent : MediaPreviewMobileModalComponent,
      {},
    );

    mediaPreviewModalRef.content.size = null;

    const modal = mediaPreviewModalRef.content;
    modal.downloadUrl = pdfDocument.url;

    if (type.toLowerCase() === 'mandate') {
      modal.filename = 'Maklermandat.pdf';
    } else if (type.toLowerCase() === 'power_of_attorney') {
      modal.filename = 'Maklervollmacht.pdf';
    } else {
      modal.filename = 'Datenschutzeinwilligung.pdf';
    }

    modal.type = 'PDF';
  }

  protected revokeBrokerContract(): void {
    const confirmationModalRef = this.modalService.show(ConfirmationModalComponent, { ignoreBackdropClick: true });
    confirmationModalRef.content.confirmationTitle = 'Bestätigung';
    confirmationModalRef.content.confirmationMessage = 'Bist du sicher, dass du Dein Mandat zurücknehmen möchtest?';
    confirmationModalRef.content.actionLabelBtn = 'Widerrufen';
    confirmationModalRef.content.danger = true;

    confirmationModalRef.content.action.subscribe((value: boolean): void => {
      confirmationModalRef.hide();

      if (value) {
        this.brokerMandateApprovalService.revoke().subscribe((): void => {
          this.brokerMandate.status = 'REVOCATION_REQUESTED';
          this.toasterService.info('Mandat widerrufen');
          this.finishBrokerContract.emit(false);
        });
      } else {
        this.finishBrokerContract.emit(false);
      }
    });
  }

  protected nextStep(): void {
    this.currentStep = this.currentStep + 1;
  }

  protected previousStep(): void {
    this.currentStep = this.currentStep - 1;
  }

  protected verifierUserData(person: MandatePersonData): void {
    if (person) {
      this.person.firstName = person.firstName;
      this.person.lastName = person.lastName;
      this.person.street = person.street;
      this.person.houseNumber = person.houseNumber;
      this.person.zip = person.zip;
      this.person.city = person.city;
      this.person.birthday = person.birthday;

      this.brokerMandateApprovalService.postMandatePersonData(this.person).subscribe();
      this.nextStep();
    } else {
      this.nextStep();
    }
  }

  protected changeStepThreeTitle(): void {
    this.stepThreeTitle = 'Fertig';
  }

  protected closeModal(emitAction: boolean): void {
    this.modalRef.hide();
    this.finishBrokerContract.emit(emitAction);
  }

  private getServices(): void {
    this.brokerMandateApprovalService.getMandatePersonData().subscribe({
      next: (data: MandatePersonData): void => {
        this.person = data;
      },
      error: (error): void => {
        console.log(error);
      },
    });
  }
}
