<div class="background-information-container" [style.padding-bottom.px]="safeAreaBottom">
  <button class="btn pl-0" (click)="hideBackgroundInformation.emit(false)">
    <i class="bi bi-arrow-left theme-color mr-10"></i>
    <span class="theme-color">Zurück</span>
  </button>

  <div class="background-information-container">
    <div class="font-size-h4 theme-color font-w600 my-20" [innerHTML]="title"></div>
    <div class="mb-20 theme-color" [innerHTML]="description"></div>
  </div>
</div>
