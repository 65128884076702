import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';

import { SigningPadComponent } from '../../../components/signing-pad/signing-pad.component';

import { ConsentTypeEnum } from '../../../enum';

import { BrokerMandateApprovalRequestModel, Company, ContractOverview, MandateApproval, Person } from '../../../model';

import { BrokerMandateApprovalService, ConsentService, UserSettingsService } from '../../../service';
import { ConsentModalComponent } from '../../consent-modal/consent-modal.component';

class PdfDocument {
  public readonly url: string;

  public constructor(private readonly blob: Blob) {
    this.url = URL.createObjectURL(blob);
  }

  public destroy(): void {
    URL.revokeObjectURL(this.url);
  }
}

type PdfType = 'MANDATE' | 'POWER_OF_ATTORNEY' | 'PRIVACY_CONSENT';

@Component({
  standalone: true,
  selector: 'app-contract-end-step',
  templateUrl: 'contract-end-step.component.html',
  styleUrls: ['contract-end-step.component.scss'],
  imports: [PopoverModule, SigningPadComponent],
})
export class ContractEndStepComponent {
  @Input() brokerMandate: MandateApproval = new MandateApproval();
  @Input() insuranceContracts: ContractOverview[];

  @Output() changeTitle = new EventEmitter();
  @Output() closeModal = new EventEmitter();
  @Output() downloadPDF = new EventEmitter<PdfType>();
  @Output() showPreview = new EventEmitter<PdfType>();

  public contractFinished: boolean = false;
  public loadingMandate: boolean = false;
  public signature: any;

  public company: Company = new Company();
  public person: Person = new Person();
  public consentType = ConsentTypeEnum;

  public readonly documents: Partial<Record<PdfType, PdfDocument>> = {};
  public readonly types = [
    {
      type: 'MANDATE',
      name: 'Maklermandat',
    },
    {
      type: 'POWER_OF_ATTORNEY',
      name: 'Maklervollmacht',
    },
    {
      type: 'PRIVACY_CONSENT',
      name: 'Datenschutzeinwilligung',
    },
  ] as const;

  constructor(
    private brokerMandateApprovalService: BrokerMandateApprovalService,
    private modalService: BsModalService,
    private userSettingsService: UserSettingsService,
    private consentService: ConsentService,
  ) {
    this.userData();
  }

  protected addSignature(event): void {
    if (event) {
      this.signature = event;
    } else {
      this.signature = null;
    }
  }

  protected finishContract(): void {
    this.loadingMandate = true;

    const request: BrokerMandateApprovalRequestModel = {
      brokerMandateId: this.brokerMandate.brokerMandateId,
      signatureBase64: this.signature,
      contractIds: [],
    };

    this.insuranceContracts.forEach((contract: ContractOverview): void => {
      if (contract?.selected) {
        request.contractIds.push(contract.id);
      }
    });

    this.brokerMandateApprovalService.postBrokerMandateApproval(request).subscribe({
      next: (): void => {
        this.loadingMandate = false;
        this.contractFinished = true;
        this.changeTitle.emit();
      },
      error: (error): void => {
        console.log(error);
      },
    });
  }

  private userData(): void {
    this.userSettingsService.getUserProfile().subscribe({
      next: (data: Person): void => {
        this.person = data;
        this.company = data.advisor?.company;
      },
      error: (error): void => {
        console.log(error);
      },
    });
  }

  protected consentForCompany(con: ConsentTypeEnum): void {
    this.consentService.consentByTypeAndCompany(ConsentTypeEnum[con], this.company.id).subscribe({
      next: (data): void => {
        const consentModalRef = this.modalService.show(ConsentModalComponent, {});
        consentModalRef.content.title = data.title;
        consentModalRef.content.description = data.html;

        consentModalRef.content.action.subscribe((value: boolean): void => {
          if (value === true) {
            consentModalRef.hide();
          }
        });
      },
      error: (error): void => {
        console.log(error);
      },
    });
  }

  protected consent(con: ConsentTypeEnum): void {
    this.consentService.consentByType(ConsentTypeEnum[con]).subscribe({
      next: (data): void => {
        const consentModalRef = this.modalService.show(ConsentModalComponent, {});
        consentModalRef.content.title = data.title;
        consentModalRef.content.description = data.html;

        consentModalRef.content.action.subscribe((value: boolean): void => {
          if (value === true) {
            consentModalRef.hide();
          }
        });
      },
      error: (error): void => {
        console.log(error);
      },
    });
  }
}
