import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

import { AnalysisCategory, AnalysisReport, AnalysisReportRequest, CreateUpdateAnalysis } from '../model';

@Injectable({
  providedIn: 'root',
})
export class CardManagerAnalysisService {
  constructor(private http: HttpClient) {}

  public getListsOfAnalysis(): Observable<AnalysisCategory[]> {
    return this.http.get<AnalysisCategory[]>(`${environment.apiEndpoint}/open-banking/analysis`);
  }

  public createReport(reportRequest: AnalysisReportRequest): Observable<AnalysisReport> {
    return this.http.post<AnalysisReport>(`${environment.apiEndpoint}/open-banking/analysis/report`, reportRequest);
  }

  public createAnalysis(data: CreateUpdateAnalysis): Observable<AnalysisCategory> {
    return this.http.post<AnalysisCategory>(`${environment.apiEndpoint}/open-banking/analysis`, data);
  }

  public updateAnalysis(id: string, data: CreateUpdateAnalysis): Observable<AnalysisCategory> {
    return this.http.put<AnalysisCategory>(`${environment.apiEndpoint}/open-banking/analysis/${id}`, data);
  }

  public deleteAnalysis(id: string): Observable<void> {
    return this.http.delete<void>(`${environment.apiEndpoint}/open-banking/analysis/${id}`);
  }
}
