import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, switchMap, tap, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { OtherDetails, UpdateOtherDetails, WealthAttachments, WealthOther } from '../model';

@Injectable({
  providedIn: 'root',
})
export class WealthOtherService {
  public filteredImages$ = new BehaviorSubject<{ attachmentUrl: string; id: string }[]>([]);
  private attachmentsSubject = new BehaviorSubject<any[]>([]);
  public attachments$ = this.attachmentsSubject.asObservable();

  constructor(private http: HttpClient) {}

  public saveOther(other: WealthOther): Observable<any> {
    return this.http.post<any>(`${environment.apiEndpoint}/wealth/b2c/other`, other);
  }

  public getOtherById(id: number): Observable<OtherDetails> {
    return this.http.get<OtherDetails>(`${environment.apiEndpoint}/wealth/b2c/other/${id}`).pipe(
      map((res: OtherDetails) => {
        this.updateAttachments(res.attachments);
        return res;
      }),
    );
  }

  public updateOtherById(id: number, other: UpdateOtherDetails): Observable<OtherDetails> {
    return this.http.put<OtherDetails>(`${environment.apiEndpoint}/wealth/b2c/other/${id}`, other).pipe(
      map((res: OtherDetails) => {
        this.updateAttachments(res.attachments);
        return res;
      }),
    );
  }

  public deleteOtherById(id: number): Observable<any> {
    return this.http.delete<any>(`${environment.apiEndpoint}/wealth/b2c/other/${id}`);
  }

  public uploadImageToOtherUrl(url: string, file: File, id: number): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': file.type,
    });
    return this.http.put(url, file, { headers, observe: 'response', responseType: 'text' }).pipe(
      switchMap(() => this.getOtherById(id)),
      tap((res: OtherDetails): void => {
        this.updateAttachments(res.attachments);
      }),
    );
  }

  public updateAttachments(attachments: WealthAttachments[]): void {
    const allAttachments = attachments.flatMap((attachment: WealthAttachments) =>
      attachment.properties.map((property) => ({
        attachmentUrl: property.attachmentUrl,
        attachmentType: property.attachmentType,
        attachmentId: String(attachment.attachmentId),
      })),
    );
    this.attachmentsSubject.next(allAttachments);
  }

  public filterImagesByType(type: string): void {
    this.attachments$
      .pipe(
        map((attachments: any) =>
          attachments
            .filter((attachment) => attachment.attachmentType === type)
            .map((attachment) => ({
              attachmentUrl: attachment.attachmentUrl,
              id: attachment.attachmentId,
            })),
        ),
      )
      .subscribe((filteredImages) => {
        this.filteredImages$.next(filteredImages);
      });
  }

  private handleError(error: any): Observable<never> {
    console.error(error);
    return throwError(error);
  }
}
