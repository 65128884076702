import { Component, EventEmitter, Input, Output } from '@angular/core';

import { PersonProfile } from '../../../model';

import { MobilityStatusEnum, RelationshipStatusEnum } from '../../../enum';
import { SharedModule } from '../../../shared.module';
import { NgClass } from '@angular/common';

@Component({
  standalone: true,
  selector: 'app-onboarding-mobility-step',
  templateUrl: 'onboarding-mobility-step.component.html',
  styleUrls: ['onboarding-mobility-step.component.scss'],
  imports: [SharedModule, NgClass],
})
export class OnboardingMobilityStepComponent {
  @Input() personProfile: PersonProfile;

  @Output() triggerNextStep = new EventEmitter();
  @Output() triggerPreviousStep = new EventEmitter();

  protected mobilityStatusEnum = MobilityStatusEnum;
  protected enumObject: string[] = Object.keys(RelationshipStatusEnum);

  constructor() {}

  protected setValue(key: any): void {
    console.log(this.personProfile, 'person');
    const index: number = this.personProfile.mobilityStatus.findIndex((e: MobilityStatusEnum): boolean => e === key);
    if (key === 'NONE') {
      this.personProfile.mobilityStatus = [];
      this.personProfile.mobilityStatus.push(key);
      this.triggerNextStep.emit();
    } else {
      const none: number = this.personProfile.mobilityStatus.findIndex(
        (e: MobilityStatusEnum): boolean => e === ('NONE' as any),
      );
      if (none > -1) {
        this.personProfile.mobilityStatus.splice(none, 1);
      }
      if (index > -1) {
        this.personProfile.mobilityStatus.splice(index, 1);
      } else {
        this.personProfile.mobilityStatus.push(key);
      }
    }
  }
}
