<div class="confirmation-modal-container" [style.padding-bottom.px]="safeAreaBottom">
  <div class="d-flex d-md-none justify-content-center pt-10" (click)="bsModalRef.hide()">
    <img src="https://static-assets.vinlivt.de/icons/misc/mobile-gray-line.png" alt="line" />
  </div>

  <div class="modal-body">
    <div class="row mb-15">
      <div class="col-12">
        <div class="section-title">{{ confirmationTitle }}</div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 theme-color">{{ confirmationMessage }}</div>
    </div>

    <div class="row mt-30">
      <div class="col-12 text-right">
        <a href="javascript:void(0)" class="btn theme-secondary-color" (click)="action.emit(false)">Abbrechen</a>

        @if (danger && !success) {
          <button class="btn btn-purple btn-rounded ml-20" [disabled]="disableButton" (click)="action.emit(true)">
            @if (actionLabelBtn) {
              <span>{{ actionLabelBtn }}</span>
            } @else {
              <span>L&ouml;schen</span>
            }

            @if (disableButton) {
              <span class="ml-10">
                <div class="spinner-border text-white" role="status" style="width: 14px; height: 14px">
                  <span class="sr-only">Bitte warten...</span>
                </div>
              </span>
            }
          </button>
        }

        <!-- success btn -->
        @if (!danger && success) {
          <button class="btn btn-purple btn-rounded ml-20" (click)="action.emit(true)">
            @if (actionLabelBtn) {
              <span>{{ actionLabelBtn }}</span>
            } @else {
              <span>L&ouml;schen</span>
            }
          </button>
        }

        <!-- default btn -->
        @if (!danger && !success) {
          <button class="btn btn-purple btn-rounded ml-20" (click)="action.emit(true)">
            @if (actionLabelBtn) {
              <span>{{ actionLabelBtn }}</span>
            } @else {
              <span>L&ouml;schen</span>
            }
          </button>
        }
      </div>
    </div>
  </div>
</div>
