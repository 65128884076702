import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgClass } from '@angular/common';

import { SharedModule } from '../../../shared.module';

import { PersonProfile } from '../../../model';

import { HousingSituationStatusEnum } from '../../../enum';

@Component({
  standalone: true,
  selector: 'app-onboarding-housing-situation-step',
  templateUrl: 'onboarding-housing-situation-step.component.html',
  styleUrls: ['onboarding-housing-situation-step.component.scss'],
  imports: [SharedModule, NgClass],
})
export class OnboardingHousingSituationStepComponent {
  @Input() personProfile: PersonProfile;

  @Output() triggerNextStep = new EventEmitter();
  @Output() triggerPreviousStep = new EventEmitter();

  protected housingSituationStatusEnum = HousingSituationStatusEnum;
  protected enumObject: string[] = Object.keys(HousingSituationStatusEnum);

  constructor() {}

  protected setValue(key: any): void {
    this.personProfile.housingSituation = key;
    this.triggerNextStep.emit();
  }
}
