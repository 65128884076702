import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

import WithSafeArea from '../utils/WithSafeArea';

@Injectable({
  providedIn: 'root',
})
export class ModalsSafeAreaService extends WithSafeArea(Object) {
  public deviceInfo: string;

  constructor(private deviceService: DeviceDetectorService) {
    super();
    this.deviceInfo = this.deviceService.getDeviceInfo().device.toLowerCase();
  }

  public getSafeAreaBottom(): number {
    if (this.deviceInfo === 'android') {
      return this.safeAreaBottom;
    } else if (this.deviceInfo === 'iphone') {
      return this.safeAreaBottom - 16;
    } else {
      return 0;
    }
  }
}
