<div class="broker-contract-modal-container" [style.padding-bottom.px]="safeAreaBottom">
  <div class="d-flex d-md-none justify-content-center pt-10" (click)="modalRef.hide()">
    <img src="https://static-assets.vinlivt.de/icons/misc/mobile-gray-line.png" alt="line" />
  </div>

  @if (
    brokerMandate?.status === 'EXIST_ON_POOL_SITE' ||
    brokerMandate?.status === 'OPEN' ||
    brokerMandate?.status === 'REVOCATION_CONFIRMED' ||
    brokerMandate?.status?.startsWith('REJECTED')
  ) {
    <div class="modal-header p-20">
      <div class="row d-flex flex-column-reverse flex-md-row" style="width: 115%">
        @switch (currentStep) {
          @case (1) {
            <div class="col-12 col-md-8 text-center text-md-left">
              <div class="section-title">Start</div>
            </div>
          }

          @case (2) {
            <div class="col-12 col-md-8 d-flex align-items-center justify-content-center justify-content-md-start">
              <img
                src="https://static-assets.vinlivt.de/icons/premium/purple/320/Document%202.png"
                width="18"
                alt="note image"
              />
              <div class="ml-10 section-title">Daten prüfen</div>
            </div>
          }

          @case (3) {
            <div class="col-12 col-md-8 d-flex align-items-center justify-content-center justify-content-md-start">
              <img
                src="https://static-assets.vinlivt.de/icons/premium/purple/320/Pencil 1.png"
                width="18"
                alt="pencil image"
              />
              <div class="ml-10 section-title">{{ stepThreeTitle }}</div>
            </div>
          }
        }

        @if (currentStep !== 1 && stepThreeTitle !== 'Fertig') {
          <div class="col-12 col-md-4 mb-10">
            <div class="d-flex align-items-center justify-content-center justify-content-md-end">
              <div
                class="mr-10 step-btn"
                [ngClass]="{
                  'step-btn-active': currentStep === 2,
                  'step-btn-finished': currentStep !== 2
                }"
              >
                <div class="inside-circle d-flex align-items-center justify-content-center">1</div>
              </div>

              <!--              <div-->
              <!--                class="mr-10 step-btn"-->
              <!--                [ngClass]="{-->
              <!--                  'step-btn-active': currentStep === 3,-->
              <!--                  'step-btn-finished': currentStep > 3-->
              <!--                }"-->
              <!--              >-->
              <!--                <div class="inside-circle d-flex align-items-center justify-content-center">2</div>-->
              <!--              </div>-->

              <div
                class="mr-10 step-btn"
                [ngClass]="{ 'step-btn-active': currentStep === 3, 'step-btn-finished': currentStep > 3 }"
              >
                <div class="inside-circle d-flex align-items-center justify-content-center">2</div>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  }

  <div class="modal-body p-20">
    <!-- if mandate ready to fill or rejected -->
    @if (
      brokerMandate?.status === 'OPEN' ||
      brokerMandate?.status === 'REVOCATION_CONFIRMED' ||
      brokerMandate?.status?.startsWith('REJECTED')
    ) {
      @switch (currentStep) {
        @case (1) {
          <app-contract-start-step
            (triggerNextStep)="nextStep()"
            (triggerSkipContract)="finishBrokerContract.emit(false)"
          ></app-contract-start-step>
        }

        @case (2) {
          <app-data-verification-step
            [person]="person"
            (verifierData)="verifierUserData($event)"
            (triggerPreviousStep)="previousStep()"
          ></app-data-verification-step>
        }

        @case (3) {
          <app-contract-end-step
            [brokerMandate]="brokerMandate"
            [insuranceContracts]="insuranceContracts"
            (changeTitle)="changeStepThreeTitle()"
            (downloadPDF)="download($event)"
            (showPreview)="preview($event)"
            (closeModal)="closeModal(true)"
          ></app-contract-end-step>
        }
      }
      <!-- if mandate already sent -->
    } @else if (brokerMandate?.status === 'PENDING' || brokerMandate?.status === 'REVOCATION_REQUESTED') {
      <app-contract-pending-view
        [brokerMandate]="brokerMandate"
        (downloadPDF)="download($event)"
        (showPreview)="preview($event)"
        (closeModal)="closeModal(false)"
      ></app-contract-pending-view>
      <!-- if mandate already approved -->
    } @else if (brokerMandate?.status === 'APPROVED' || brokerMandate?.status === 'EXIST_ON_POOL_SITE') {
      <app-contract-approved-view
        [brokerMandate]="brokerMandate"
        (downloadPDF)="download($event)"
        (showPreview)="preview($event)"
        (revokeBrokerContract)="revokeBrokerContract()"
        (closeModal)="closeModal(false)"
      ></app-contract-approved-view>
    }
  </div>
</div>
