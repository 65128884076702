import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { forkJoin, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export interface ImageToAdd {
  filesize: number;
  file: File;
}

export interface ImageObject {
  url: string;
  id: string;
}

@Injectable({
  providedIn: 'root',
})
export class WealthImageUploadService {
  private maxFiles = 20;
  private maxFileSize = 5 * 1024 * 1024; // 5MB in Bytes

  onFileSelected(event: any, formControl: AbstractControl, imagesArray: string[] | ImageObject[]): void {
    const files: FileList = event.target.files ? event.target.files : event.dataTransfer.files;
    const newImages: ImageToAdd[] = [];
    let totalFiles = formControl.value ? formControl.value.length : 0;

    Array.from(files).forEach((file: File) => {
      if (totalFiles >= this.maxFiles) {
        alert(`Maximale Anzahl von ${this.maxFiles} Bildern erreicht.`);
        return;
      }

      if (file.size > this.maxFileSize) {
        alert(`Datei "${file.name}" ist größer als 5MB und wird übersprungen.`);
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        if (Array.isArray(imagesArray)) {
          if (typeof imagesArray[0] === 'string' || imagesArray.length === 0) {
            (imagesArray as string[]).push(reader.result as string);
          } else {
            (imagesArray as ImageObject[]).push({ url: reader.result as string, id: '' });
          }
        }
      };
      reader.readAsDataURL(file);

      newImages.push({ filesize: file.size, file: file });
      totalFiles++;
    });

    const currentImages = formControl.value || [];
    formControl.patchValue([...currentImages, ...newImages]);
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  onDrop(event: DragEvent, formControl: AbstractControl, imagesArray: string[] | ImageObject[]): void {
    event.preventDefault();
    this.onFileSelected(event, formControl, imagesArray);
  }

  removeImage(index: number, formControl: AbstractControl, imagesArray: string[] | ImageObject[]): void {
    imagesArray.splice(index, 1);
    const currentImages = formControl.value;
    currentImages.splice(index, 1);
    formControl.patchValue(currentImages);
  }

  deleteImage(
    index: number,
    attachmentId: string,
    formControl: AbstractControl,
    imagesArray: string[] | ImageObject[],
  ): void {
    const imageToDelete = formControl.get('imageToDelete').value;
    imageToDelete.push(attachmentId);

    formControl.patchValue({
      imageToDelete,
    });

    // Remove image from the images array
    imagesArray.splice(index, 1);

    // Ensure there's always an active image if any images remain
    if (imagesArray.length > 0) {
      const carouselInner = document.querySelector('.carousel-inner');
      if (carouselInner) {
        const items = carouselInner.querySelectorAll('.carousel-item');
        if (items.length > 0) {
          items[0].classList.add('active');
        }
      }
    }
  }

  uploadImages(
    uploadUrls: string[],
    images: ImageToAdd[],
    id: string | number,
    uploadService: any,
    category: string,
  ): Observable<any[]> {
    const uploadMethodName = `uploadImageTo${category.charAt(0).toUpperCase() + category.slice(1)}Url`;

    if (typeof uploadService[uploadMethodName] !== 'function') {
      console.error(`Upload method ${uploadMethodName} not found in upload service`);
      return throwError(() => new Error(`Upload method for category ${category} not found`));
    }

    const uploadObservables = images.map((image, index) =>
      uploadService[uploadMethodName](uploadUrls[index], image.file, id).pipe(
        catchError((error) => {
          console.error(`Error uploading image to ${category}:`, uploadUrls[index], ':', error);
          return throwError(() => error);
        }),
      ),
    );

    return forkJoin(uploadObservables);
  }
}
