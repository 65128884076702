import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { ModalsSafeAreaService } from '../global-store';

@Directive({
  selector: '[appModalSafeAreaBottom]',
})
export class ModalSafeAreaPipe implements OnInit {
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private modalsSafeAreaService: ModalsSafeAreaService,
  ) {}

  public ngOnInit() {
    const paddingBottom = this.modalsSafeAreaService.getSafeAreaBottom();
    this.renderer.setStyle(this.el.nativeElement, 'padding-bottom', `${paddingBottom}px`);
  }
}
