import { Component, Input } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { BsModalService } from 'ngx-bootstrap/modal';

import { ClientInvitationModalComponent } from '../../modals/client-invitation-modal/client-invitation-modal.component';

import { Advisor } from '../../model';

@Component({
  standalone: true,
  selector: 'app-client-invitation-banner',
  templateUrl: 'client-invitation-banner.component.html',
  styleUrls: ['client-invitation-banner.component.scss'],
  imports: [NgOptimizedImage],
})
export class ClientInvitationBannerComponent {
  @Input() advisorInfo: Advisor = new Advisor();
  constructor(private bsModalService: BsModalService) {}

  protected openClientInvitationModal(): void {
    const clientInvitationModalRef = this.bsModalService.show(ClientInvitationModalComponent, {});
    clientInvitationModalRef.content.advisorInfo = this.advisorInfo;
  }
}
