import { AfterViewInit, Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import SignaturePad from 'signature_pad';

@Component({
  standalone: true,
  selector: 'app-signing-pad',
  templateUrl: './signing-pad.component.html',
  styleUrls: ['./signing-pad.component.scss'],
})
export class SigningPadComponent implements AfterViewInit {
  @ViewChild('signPad', { static: false }) signPad!: ElementRef<HTMLCanvasElement>;

  @Output() returnSignature = new EventEmitter();

  protected signPadRender: boolean = true;
  protected editMode: boolean = true;

  protected signature: SignaturePad;
  protected imgSignature: string;

  constructor() {}

  public ngAfterViewInit(): void {
    this.createSigningPad();
  }

  private createSigningPad(): void {
    this.fitToContainer(this.signPad.nativeElement);

    this.signature = new SignaturePad(this.signPad.nativeElement, {
      minWidth: 1,
      maxWidth: 1,
      penColor: '#0063F5',
    });

    this.signPadRender = false;
  }

  // add this function if modal, because touchpad is broken
  private fitToContainer(canvas): void {
    // Make it visually fill the positioned parent
    canvas.style.width = '100%';
    canvas.style.height = '100%';
    // ...then set the internal size to match
    canvas.width = canvas.offsetWidth;
    canvas.height = canvas.offsetHeight;
  }

  protected clearSignature(): void {
    this.signature.clear();
    this.returnSignature.emit(null);
  }

  protected saveSignature(): void {
    this.imgSignature = this.signature.toDataURL('image/png');
    this.returnSignature.emit(this.imgSignature);

    this.editMode = false;
  }

  protected editSignature(): void {
    this.editMode = true;
    this.returnSignature.emit(null);

    setTimeout((): void => {
      this.createSigningPad();
    }, 500);
  }
}
