import { Environment } from './ienvironment';

export const environment: Environment = {
  production: false,
  apiEndpoint: 'https://api.dev.vinlivt.de',
  webSocketEndpoint: 'wss://websocket.dev.vinlivt.de',
  finapi: 'sandbox',
  keycloakUrl: 'https://api.dev.vinlivt.de/auth',
  keycloakRealm: 'b2c',
  location: 'dev',
  appUrl: 'https://app.dev.vinlivt.de',
};
