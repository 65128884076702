import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { DeviceDetectorService } from 'ngx-device-detector';

import { DashboardSpinnerService } from '../../shared/global-store/dashboard-spinner.service';
import {
  AdvisorUpdateService,
  EmptyStateHandler,
  PersonUpdateService,
  UiChangeTriggersService,
} from '../../shared/global-store';

import { OnboardingModalComponent } from '../../shared/modals/onboarding-modal/onboarding-modal.component';
import { BrokerContractModalComponent } from '../../shared/modals/broker-contract-modal/broker-contract-modal.component';
import { AdvisorCheckModalComponent } from '../../shared/modals/consents/advisor-check-modal/advisor-check-modal.component';
import { BrokerPinModalComponent } from '../../shared/modals/consents/broker-pin-modal/broker-pin-modal.component';
import { VinvlivtConsentsComponent } from '../../shared/modals/consents/vinvlivt-consents/vinvlivt-consents.component';

import {
  Advisor,
  AdvisorConsentResponse,
  Company,
  CompanySettings,
  FullPersonProfile,
  Person,
  StatisticsResponse,
  UserConsent,
} from '../../shared/model';

import {
  AUTH_SERVICE_TOKEN,
  AuthService,
  CompanySettingsService,
  ConsentService,
  OpenBankingService,
  PensionCheckService,
  StatisticService,
  UserSettingsService,
} from '../../shared/service';

import WithSafeArea from '../../shared/utils/WithSafeArea';
import WithSubscription from '../../shared/utils/WithSubscriptions';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
})
export class HeaderComponent extends WithSafeArea(WithSubscription(Object)) implements OnInit {
  @ViewChild('headerContainer') myElementRef!: ElementRef;

  @Input() deepDiveNavbar: boolean;
  @Input() firstLayer: boolean;
  @Input() title: string;

  @Output() open = new EventEmitter<any>();

  protected loading: boolean = true;
  protected leaveVinhubBtn: boolean = false;
  protected headerContainerBoxShadow: boolean = true;
  protected isPersonProfileFilled: boolean = false;

  protected advisorConsentResponse: AdvisorConsentResponse = new AdvisorConsentResponse();
  protected statisticsResponse: StatisticsResponse = new StatisticsResponse();
  protected userConsents: Array<UserConsent> = new Array<UserConsent>();

  protected person: Person = new Person();
  protected company: Company;
  protected companyLogoUrl: string;
  protected deviceInfo = null;

  protected config: ModalOptions = {
    ignoreBackdropClick: true,
    keyboard: false,
  };

  constructor(
    @Inject(AUTH_SERVICE_TOKEN) private readonly authService: AuthService,
    private readonly personUpdateService: PersonUpdateService,
    private readonly emptyStatesService: EmptyStateHandler,
    private readonly userSettingsService: UserSettingsService,
    private readonly deviceService: DeviceDetectorService,
    protected readonly router: Router,
    private readonly statisticService: StatisticService,
    private readonly consentService: ConsentService,
    private readonly modalService: BsModalService,
    private readonly advisorUpdateService: AdvisorUpdateService,
    private readonly openBankingService: OpenBankingService,
    private readonly companySettingsService: CompanySettingsService,
    private readonly uiChangeTrigger: UiChangeTriggersService,
    private readonly dashboardSpinnerService: DashboardSpinnerService,
    private readonly pensionCheckService: PensionCheckService,
  ) {
    super();
    this.deviceInfo = this.deviceService.getDeviceInfo().device.toLowerCase();
    this.windowSize();
  }

  @HostListener('window:resize')
  onResize(): void {
    this.windowSize();
  }

  protected windowSize(): void {
    const windowWidth = window.innerWidth;
    this.leaveVinhubBtn = windowWidth <= 1000;
  }

  protected setCorrectSafeAreaByDevice(): number {
    if (this.deviceInfo === 'android') {
      return this.safeAreaTop + 8;
    } else if (this.deviceInfo === 'iphone') {
      return this.safeAreaTop;
    } else {
      return 19;
    }
  }

  public ngOnInit(): void {
    if (this.authService.loggedIn) {
      this.loadUserProfile();
      this.checkPersonProfile();
    }

    this.openBankingService.checkProviderAccess().subscribe({
      next: (res): void => {
        this.emptyStatesService.hasProviderAccounts = res.status === 200;
      },
      error: (error): void => {
        this.emptyStatesService.hasProviderAccounts = false;
        console.log(error);
      },
    });

    if (window.innerWidth < 770) {
      this.uiChangeTrigger.getUpdatedBoxShadow().subscribe({
        next: (value: boolean): void => {
          this.headerContainerBoxShadow = value;
        },
        error: (error): void => {
          console.log(error);
        },
      });
    }

    this.loadCompanySettings();
    this.listenToSubject(this.userSettingsService.profileDataChangedSubject, () => this.loadUserProfile());
  }

  protected checkPersonProfile(): void {
    this.pensionCheckService.loadPensionCheck().subscribe({
      next: (data: FullPersonProfile): void => {
        this.isPersonProfileFilled = data.fullyAnswered;
      },
      error: (error): void => {
        console.log(error);
      },
    });
  }

  protected back(): void {
    if (this.router.url.includes('vinhub')) {
      this.router.navigate(['/dashboard']);
    } else if (this.router.url.includes('bank-accounts-overview')) {
      this.router.navigate(['/bank-card-manager']);
    } else {
      window.history.back();
    }
  }

  protected showUserSidebar(): void {
    this.open.emit(true);
  }

  protected openBrokerContractModal(): void {
    const brokerContractModalRef = this.modalService.show(BrokerContractModalComponent, {
      ignoreBackdropClick: true,
    });

    brokerContractModalRef.content.brokerMandate = this.person?.mandateApproval;

    brokerContractModalRef.content.finishBrokerContract.subscribe((value: boolean): void => {
      if (value) {
        brokerContractModalRef.hide();
        setTimeout((): void => {
          this.person.mandateApproval.status = 'PENDING';
        }, 200);
      } else {
        brokerContractModalRef.hide();
      }
    });
  }

  private checkConsents(): void {
    this.loadStatistics();
    this.checkMandatoryVinlivtConsents();
  }

  private loadCompanySettings(): void {
    this.companySettingsService.getCompanySettingsData().subscribe({
      next: (data: CompanySettings): void => {
        if (data?.companyLogoUrl) {
          this.companyLogoUrl = data?.companyLogoUrl;
        } else {
          this.companyLogoUrl = null;
        }
      },
      error: (error): void => {
        console.log(error);
      },
    });
  }

  private loadStatistics(): void {
    this.statisticService.loadStatistics().subscribe({
      next: (data: StatisticsResponse): void => {
        this.statisticsResponse = data;
      },
      error: (error): void => {
        console.log(error);
      },
    });
  }

  private loadUserProfile(update?: boolean): void {
    this.userSettingsService.getUserProfile().subscribe({
      next: (data: Person): void => {
        this.person = data;
        this.loading = false;

        this.personUpdateService.getUpdatedPerson().subscribe({
          next: (value: Person): void => {
            if (value?.uid) {
              this.person = value;
            }
          },
          error: (error): void => {
            console.log(error);
          },
        });

        if (update) {
          this.advisorUpdateService.setUpdatedAdvisor(data.advisor);
        }

        this.checkConsents();
      },
      error: (error): void => {
        console.log(error);
        this.openBrokerPinModal();
      },
    });
  }

  private acceptUserMandatoryConsents(): void {
    this.consentService.acceptVinlivtMandatoryConsents().subscribe();
  }

  // checkForUserProfileData(): void {
  //   if (this.person?.firstLogin === false) {
  //     if (
  //       this.isEmptyString(this.person.street) ||
  //       this.isEmptyString(this.person.houseNumber) ||
  //       this.isEmptyString(this.person.zip) ||
  //       this.isEmptyString(this.person.city)
  //     ) {
  //       this.userNeedToFillProfileModalRef = this.modalService.show(UserDataModalComponent, {});
  //
  //       this.userNeedToFillProfileModalRef.content.action.subscribe((value: boolean) => {
  //         if (value === true) {
  //           this.userNeedToFillProfileModalRef.hide();
  //           this.router.navigate(['/settings']);
  //         } else {
  //           this.userNeedToFillProfileModalRef.hide();
  //           this.userNeedToFillProfileModalRef = null;
  //         }
  //       });
  //     }
  //   }
  // }

  private acceptAdvisorMandatoryConsents(): void {
    this.consentService.acceptAdvisorMandatoryConsents().subscribe({
      next: (): void => {
        // trigger changes have changed so other services can reload data
        setTimeout(() => this.userSettingsService.triggerChanges(), 1000);
      },
      error: (error): void => {
        console.log(error);
      },
    });
  }

  private checkMandatoryVinlivtConsents(): void {
    this.consentService.getVinlivtMandatoryConsent().subscribe({
      next: (data): void => {
        this.userConsents = data;

        if (this.userConsents.length > 0) {
          this.openVinlivtConsentsModal();
        } else {
          this.checkMandatoryAdvisorConsents();
        }
      },
      error: (error): void => {
        console.log(error);
      },
    });
  }

  private checkMandatoryAdvisorConsents(): void {
    this.consentService.getAdvisorMandatoryConsent().subscribe({
      next: (data: AdvisorConsentResponse): void => {
        this.advisorConsentResponse = data;
        this.company = this.advisorConsentResponse.company;

        this.config.initialState = {
          company: this.company,
        };

        this.userConsents = this.advisorConsentResponse.userConsents;

        if (this.userConsents.length > 0) {
          this.openAdvisorConsentsModal();
          // } else {
          //   this.checkForUserProfileData();
        } else {
          this.openOnboardingModal();
        }
      },
      error: (error): void => {
        console.log(error);
      },
    });
  }

  private openVinlivtConsentsModal(): void {
    const vinlivtCheckModalRef = this.modalService.show(VinvlivtConsentsComponent, this.config);

    vinlivtCheckModalRef.content.action.subscribe((value: boolean): void => {
      if (value) {
        this.acceptUserMandatoryConsents();
        vinlivtCheckModalRef.hide();
        setTimeout((): void => {
          this.checkMandatoryAdvisorConsents();
        }, 2500);
      } else {
        vinlivtCheckModalRef.hide();
        this.authService.logout();
      }
    });
  }

  private openAdvisorConsentsModal(): void {
    const advisorCheckModalRef = this.modalService.show(AdvisorCheckModalComponent, this.config);

    advisorCheckModalRef.content.action.subscribe((value: boolean): void => {
      if (value) {
        this.dashboardSpinnerService.setSpinner(true);
        this.acceptAdvisorMandatoryConsents();
        advisorCheckModalRef.hide();
      } else {
        advisorCheckModalRef.hide();
        this.authService.logout();
      }
    });
  }

  private openOnboardingModal(): void {
    if (!this.isPersonProfileFilled) {
      const onboardingModalRef = this.modalService.show(OnboardingModalComponent, this.config);
      onboardingModalRef.content.triggerOnboardingFinish.subscribe((): void => {
        this.isPersonProfileFilled = true;
      });
    }
  }

  private openBrokerPinModal(): void {
    const brokerPinModalRef = this.modalService.show(BrokerPinModalComponent, this.config);

    brokerPinModalRef.content.action.subscribe((advisor: Advisor): void => {
      if (advisor) {
        this.person.advisor = advisor;

        // this.loadUserProfile(false);
        setTimeout((): void => {
          if (this.person?.uid) {
            this.personUpdateService.setUpdatedPerson(this.person);
          } else {
            this.loadUserProfile();
          }
        }, 200);

        brokerPinModalRef.hide();
      } else {
        brokerPinModalRef.hide();
        this.authService.logout();
      }
    });
  }
}
