import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

import { SharedVideo, SharedVideoRequest, SharedVideoResponse } from '../model';

@Injectable({
  providedIn: 'root',
})
export class MediaStudioService {
  constructor(private http: HttpClient) {}

  public getSharedVideoList(sharedVideoRequest: SharedVideoRequest): Observable<SharedVideoResponse> {
    const params: HttpParams = new HttpParams()
      .set('owner_id', sharedVideoRequest.owner_id)
      .set('page', sharedVideoRequest.page.toString())
      .set('page_size', sharedVideoRequest.page_size.toString());

    return this.http.get<SharedVideoResponse>(`${environment.apiEndpoint}/media-studio/b2c/video/share`, { params });
  }

  public getMultipleSharedVideos(ids: number[]): Observable<SharedVideo[]> {
    return this.http.get<SharedVideo[]>(
      `${environment.apiEndpoint}/media-studio/b2c/video/share/multi?${ids
        .map((id: number): string => `id=${id}`)
        .join('&')}`,
    );
  }

  public getSharedVideoById(id: number): Observable<SharedVideo> {
    return this.http.get<SharedVideo>(`${environment.apiEndpoint}/media-studio/b2c/video/share/${id}`);
  }
}
