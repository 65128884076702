import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StepperService {
  private resetSubject = new Subject<void>();

  nextStep(
    currentStep: number,
    totalSteps: number,
  ): {
    currentStep: number;
    isNextDisabled: boolean;
    needsSubmit: boolean;
  } {
    if (currentStep < totalSteps - 1) {
      return { currentStep: currentStep + 1, isNextDisabled: false, needsSubmit: false };
    } else {
      return { currentStep, isNextDisabled: true, needsSubmit: true };
    }
  }

  prevStep(currentStep: number): { currentStep: number; isNextDisabled: boolean; needsReset: boolean } {
    if (currentStep > 0) {
      return { currentStep: currentStep - 1, isNextDisabled: false, needsReset: false };
    } else {
      return { currentStep: 0, isNextDisabled: false, needsReset: true };
    }
  }

  resetToCategories(): void {
    this.resetSubject.next();
  }

  onResetToCategories(): Observable<void> {
    return this.resetSubject.asObservable();
  }
}
