import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
    selector: '[appDragAndDrop]'
})
export class DragAndDropDirective {

    @HostBinding('class.fileover') fileOver: boolean;
    @Output() onAttachmentsSelected = new EventEmitter<File[]>();

    constructor() {
    }

    @HostListener('dragover', ['$event']) onDragOver(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        let files = evt.dataTransfer.files;
        this.fileOver = true;
    }

    @HostListener('dragleave', ['$event']) public onDragLeave(evt){
        evt.preventDefault();
        evt.stopPropagation();
        this.fileOver = false;
    }

    @HostListener('drop', ['$event']) public onDrop(evt){
        evt.preventDefault();
        evt.stopPropagation();
        this.fileOver = false;
        let files = evt.dataTransfer.files;
        if(files.length > 0) {
            this.onAttachmentsSelected.emit(files);
        }
    }
}
