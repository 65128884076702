<div
  class="app-header-container"
  [ngClass]="{ 'header-container-without-box-shadow': !headerContainerBoxShadow }"
  [style.padding-top.px]="setCorrectSafeAreaByDevice()"
>
  <!-- Mobile view -->
  <div class="mobile-header-content-navbar">
    @if (deepDiveNavbar) {
      <div class="d-flex align-items-center">
        @if (!firstLayer) {
          <button type="button" class="btn btn-header back-btn" (click)="back()">
            <i class="bi bi-arrow-left theme-color"></i>
          </button>
        }

        @if (companyLogoUrl && router.url.includes('/dashboard')) {
          <img [src]="companyLogoUrl" width="auto" height="42" alt="logo" />
        } @else {
          <div class="parent-truncate pr-15">
            <div class="child-truncate section-title">{{ title }}</div>
          </div>
        }
      </div>
    }

    <div class="navbar-user-section">
      <div class="d-flex align-items-center">
        @if (loading) {
          <ngx-skeleton-loader
            appearance="circle"
            [theme]="{
              width: '30px',
              height: '30px',
              margin: '0'
            }"
          ></ngx-skeleton-loader>
        } @else {
          @if (person?.profilePictureUrl) {
            <button type="button" class="btn btn-circle btn-lg user-icon-button" (click)="showUserSidebar()">
              <img class="img-avatar user-icon" alt="avatar" [src]="person.profilePictureUrl" />
            </button>
          } @else {
            <button class="btn btn-circle btn-lg user-icon-button" (click)="showUserSidebar()">
              <img class="img-avatar user-icon" alt="avatar" src="https://static-assets.vinlivt.de/img/misc/bird.png" />
            </button>
          }
        }
      </div>
    </div>
  </div>

  <!-- Desktop view -->
  <div
    class="header-content-navbar"
    [ngClass]="{ 'justify-content-between': router.url.includes('vinhub') && leaveVinhubBtn }"
  >
    @if (router.url.includes('vinhub') && leaveVinhubBtn) {
      <button class="btn btn-action-secondary" routerLink="/dashboard">
        <i class="bi bi-arrow-left mr-5"></i>
        Zurück
      </button>
    }

    <div class="navbar-desktop-btn-group" role="group">
      <div class="d-none d-lg-block">
        <app-unread-messages-widget></app-unread-messages-widget>
      </div>

      @if (person?.mandateApproval?.status === 'OPEN' && router.url.includes('/contracts')) {
        <button
          class="btn btn-hover d-flex align-items-center justify-content-center"
          (click)="openBrokerContractModal()"
        >
          <img
            class="mr-2"
            src="https://static-assets.vinlivt.de/icons/premium/purple/320/Shield%201.png"
            height="16"
            alt="mandate"
          />

          <span>Maklermandat</span>
        </button>
      }

      <button type="button" class="btn btn-user theme-border" id="show-user-btn" (click)="showUserSidebar()">
        @if (loading) {
          <ngx-skeleton-loader
            appearance="circle"
            [theme]="{
              width: '30px',
              height: '30px',
              margin: '0'
            }"
          ></ngx-skeleton-loader>
        } @else {
          @if (person?.profilePictureUrl) {
            <img class="img-avatar img-avatar32 user-img-icon" alt="avatar" [src]="person.profilePictureUrl" />
          } @else {
            <img
              class="img-avatar img-avatar32 user-img-icon"
              src="https://static-assets.vinlivt.de/img/misc/bird.png"
              alt="avatar"
            />
          }
        }

        <span class="d-none d-sm-inline-block ml-10">
          @if (person?.firstname && person?.lastname) {
            <span class="theme-color"> {{ person.firstname }} {{ person.lastname }} </span>
          } @else {
            <span class="theme-color">{{ person.email }}</span>
          }
        </span>
      </button>
    </div>
  </div>
</div>
