<nav class="sidebar-content-layout h-100">
  <div class="position-relative h-100">
    <div class="sidebar-content">
      <div class="content-header content-header-fullrow px-15">
        <div class="content-header-section sidebar-mini-visible-b">
          <span class="content-header-item font-w700 font-size-xl float-left animated fadeIn">
            <span class="text-dual-primary-dark">c</span><span class="text-primary">b</span>
          </span>
        </div>

        @if (!logoLoading) {
          <div class="content-header-section text-center align-parent sidebar-mini-hidden">
            <div class="content-header-item">
              @if (logo) {
                <a routerLink="/dashboard">
                  <img [src]="logo" width="115" alt="logo" />
                </a>
              }
            </div>
          </div>
        }
      </div>

      <div class="js-sidebar-scroll">
        <!-- Desktop navigation -->
        <div class="content-side content-side-full d-block">
          <ul class="nav-main">
            <li>
              <a
                class="d-flex align-items-center"
                data-toggle="layout"
                routerLink="/dashboard"
                routerLinkActive="active"
              >
                <img
                  alt="home icon"
                  class="mr-10"
                  src="https://static-assets.vinlivt.de/icons/line-icons/svg/home.svg"
                />
                <span class="sidebar-mini-hide font-w400">&Uuml;bersicht</span>
              </a>
            </li>

            <li>
              <a
                class="d-flex align-items-center"
                data-action="layout"
                data-toggle="layout"
                routerLink="/contracts"
                routerLinkActive="active"
              >
                <img
                  alt="contracts icon"
                  class="mr-10"
                  src="https://static-assets.vinlivt.de/icons/line-icons/svg/folder.svg"
                />
                <span class="sidebar-mini-hide font-w400">Vertr&auml;ge</span>
              </a>
            </li>

            <li>
              <a
                class="d-flex align-items-center"
                data-toggle="layout"
                routerLink="/bank-card-manager"
                routerLinkActive="active"
              >
                <img
                  alt="wallet icon"
                  class="mr-10"
                  src="https://static-assets.vinlivt.de/icons/line-icons/svg/wallet.svg"
                />
                <span class="sidebar-mini-hide font-w400">Konten</span>
              </a>
            </li>
            @if (isDev) {
              <li>
                <a
                  class="d-flex align-items-center"
                  routerLinkActive="active"
                  routerLink="/wealth"
                  data-toggle="layout"
                >
                  <img
                    src="https://static-assets.vinlivt.de/icons/line-icons/svg/chart%204.svg"
                    class="mr-10"
                    alt="wallet icon"
                  />
                  <span class="sidebar-mini-hide font-w400">Vermögen</span>
                </a>
              </li>
            }
            <li>
              <a class="d-flex align-items-center" data-toggle="layout" routerLink="/vinhub" routerLinkActive="active">
                <img
                  alt="chat icon"
                  class="mr-10"
                  src="https://static-assets.vinlivt.de/icons/line-icons/svg/chat.svg"
                />

                <span class="sidebar-mini-hide font-w400">Chats</span>

                @if (unreadMessages > 0) {
                  <!--                  <div class="unread-messages-bubble"></div>-->
                  <span class="ml-auto read-circle text-10">{{ unreadMessages > 10 ? '10+' : unreadMessages }}</span>
                }
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="position-absolute bottom-content d-flex flex-column w-100 gap-3 pb-15">
      <app-advisor-micro-card
        [advisorInfo]="advisorInfo"
        [loading]="advisorLoading"
        [mandateApproval]="person?.mandateApproval"
      ></app-advisor-micro-card>

      @if (companySettings?.showReferralInvitation) {
        <app-client-invitation-banner [advisorInfo]="advisorInfo"></app-client-invitation-banner>
      }
    </div>
  </div>
</nav>
