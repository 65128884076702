<div class="onboarding-user-avatar-step-container">
  <div class="row mb-15">
    <div class="col-12 text-center">
      <div class="block-title font-size-h4 fw-400 mb-20">Avatar hochladen</div>
    </div>
  </div>

  <div class="row" style="min-height: 424px">
    <div class="col-12 d-flex align-items-center justify-content-center">
      <div class="row">
        <div class="col-12 text-center text-md-left mb-20 px-0">
          @if (!showCropper && !selectedFile) {
            <div class="d-flex justify-content-center align-items-center flex-column">
              <img
                class="mb-20"
                src="{{ croppedImage ?? 'https://static-assets.vinlivt.de/img/misc/bird.png' }}"
                width="150"
                height="150"
                style="border-radius: 50%"
                alt="avatar"
              />

              <label for="file-upload" class="btn btn-sm btn-rounded btn-action-secondary d-sm-inline-block">
                <i class="fas fa-cloud-upload-alt"></i> Profilbild hochladen
              </label>

              <input
                id="file-upload"
                class="d-none"
                type="file"
                accept="image/jpeg, image/png, application/pdf"
                (change)="onFileChange($event)"
              />

              @if (imageError && imageErrorText) {
                <div class="text-danger">{{ imageErrorText }}</div>
              }
            </div>
          }

          @if (showCropper) {
            <div class="block block-bordered block-rounded px-30">
              <div class="block-header block-header-default border-b py-2">
                <div class="block-options">
                  <button
                    type="button"
                    class="btn-block-option"
                    data-toggle="block-option"
                    data-action="state_toggle"
                    data-action-mode="demo"
                  >
                    <i class="si si-refresh" (click)="resetImage()"></i>
                  </button>

                  <button
                    type="button"
                    class="btn-block-option"
                    data-toggle="block-option"
                    data-action="state_toggle"
                    data-action-mode="demo"
                  >
                    <i class="si si-action-undo" (click)="rotateLeft()"></i>
                  </button>

                  <button
                    type="button"
                    class="btn-block-option"
                    data-toggle="block-option"
                    data-action="state_toggle"
                    data-action-mode="demo"
                  >
                    <i class="si si-action-redo" (click)="rotateRight()"></i>
                  </button>

                  <button
                    type="button"
                    class="btn-block-option"
                    data-toggle="block-option"
                    data-action="state_toggle"
                    data-action-mode="demo"
                  >
                    <i class="si si-arrow-right" (click)="flipHorizontal()"></i>
                  </button>

                  <button
                    type="button"
                    class="btn-block-option"
                    data-toggle="block-option"
                    data-action="state_toggle"
                    data-action-mode="demo"
                  >
                    <i class="si si-arrow-down" (click)="flipVertical()"></i>
                  </button>

                  <button
                    type="button"
                    class="btn-block-option"
                    data-toggle="block-option"
                    data-action="state_toggle"
                    data-action-mode="demo"
                  >
                    <i class="si si-magnifier-add" (click)="zoomIn()"></i>
                  </button>

                  <button
                    type="button"
                    class="btn-block-option"
                    data-toggle="block-option"
                    data-action="state_toggle"
                    data-action-mode="demo"
                  >
                    <i class="si si-magnifier-remove" (click)="zoomOut()"></i>
                  </button>

                  <button
                    type="button"
                    class="btn-block-option"
                    data-toggle="block-option"
                    data-action="state_toggle"
                    data-action-mode="demo"
                  >
                    <i
                      class="si"
                      [ngClass]="{
                        'si-size-fullscreen': containWithinAspectRatio,
                        'si-size-actual': !containWithinAspectRatio
                      }"
                      (click)="toggleContainWithinAspectRatio()"
                    ></i>
                  </button>
                </div>
              </div>

              <div class="block-content block-content-full">
                <image-cropper
                  [imageChangedEvent]="imgChangeEvt"
                  [maintainAspectRatio]="true"
                  [containWithinAspectRatio]="containWithinAspectRatio"
                  [aspectRatio]="4 / 4"
                  [resizeToWidth]="256"
                  [cropperMinWidth]="128"
                  [onlyScaleDown]="true"
                  [roundCropper]="false"
                  [canvasRotation]="canvasRotation"
                  [transform]="transform"
                  [alignImage]="'left'"
                  [style.display]="showCropper ? null : 'none'"
                  format="png"
                  (imageCropped)="imageCropped($event)"
                  (imageLoaded)="imageLoaded()"
                  (cropperReady)="cropperReady($event)"
                  (loadImageFailed)="loadImageFailed()"
                ></image-cropper>
              </div>
            </div>

            <div class="d-flex align-items-center justify-content-center">
              <button
                class="btn btn-circle btn-clear mr-10 d-flex align-items-center justify-content-center"
                (click)="clearProfilePic()"
              >
                <i class="bi bi-x-lg"></i>
              </button>

              <button
                class="btn btn-circle btn-save d-flex align-items-center justify-content-center"
                (click)="saveProfilePic()"
              >
                <i class="bi bi-check-lg"></i>
              </button>
            </div>
          }

          @if (!showCropper && selectedFile) {
            <div class="d-flex w-100 justify-content-center">
              <img class="br-16" [src]="croppedImage" alt="avatar" width="150" height="150" />
            </div>
          }
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-30">
    <div class="col-6 text-left py-2">
      <div class="theme-secondary-color pointer-cursor" (click)="triggerPreviousStep.emit()">
        <i class="bi bi-arrow-left mr-2"></i> Zur&uuml;ck
      </div>
    </div>

    <div class="col-6 text-right">
      <button type="button" class="btn btn-rounded btn-purple px-20" (click)="saveUserAvatar()">
        <i class="bi bi-check-lg mr-2"></i>
        <span>Fertig</span>

        @if (requestLoading) {
          <span class="ml-10">
            <div class="spinner-border text-white" role="status" style="width: 14px; height: 14px">
              <span class="sr-only">Bitte warten...</span>
            </div>
          </span>
        }
      </button>
    </div>
  </div>
</div>
