import { Component, EventEmitter, Input, Output } from '@angular/core';

import { SharedModule } from '../../../shared.module';

import { PersonProfile } from '../../../model';

import { ProfessionStatusEnum } from '../../../enum';

@Component({
  standalone: true,
  selector: 'app-onboarding-profession-step',
  templateUrl: 'onboarding-profession-step.component.html',
  styleUrls: ['onboarding-profession-step.component.scss'],
  imports: [SharedModule],
})
export class OnboardingProfessionStepComponent {
  @Input() personProfile: PersonProfile;

  @Output() triggerNextStep = new EventEmitter();
  @Output() triggerPreviousStep = new EventEmitter();

  protected professionalStatus = ProfessionStatusEnum;
  protected enumObject: string[] = Object.keys(ProfessionStatusEnum);

  constructor() {}

  protected setValue(key: any): void {
    this.personProfile.professionStatus = key;
    this.triggerNextStep.emit();
  }
}
