import { transition, trigger, useAnimation } from '@angular/animations';
import { Component, EventEmitter, Inject, Input, isDevMode, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';

import {
  SidebarCloseAnimation,
  SidebarOpenAnimation,
} from '../../shared/animations/mobile-user-sidebar-toggle-animation';

import { PersonUpdateService, Theme, UiChangeTriggersService } from '../../shared/global-store';

import { AgentModalComponent } from '../../shared/modals/agent-modal/agent-modal.component';
import { ConsentModalComponent } from '../../shared/modals/consent-modal/consent-modal.component';
import { OptionsWidgetModalComponent } from '../../shared/modals/options-widget-modal/options-widget-modal.component';

import { Advisor, Company, Person } from '../../shared/model';
import { AUTH_SERVICE_TOKEN, AuthService, ConsentService, UserSettingsService } from '../../shared/service';

import { ConsentTypeEnum } from '../../shared/enum';

import WithSubscription from '../../shared/utils/WithSubscriptions';

const animationParams = {
  menuWidth: '230px',
  animationStyle: '500ms ease',
};

@Component({
  selector: 'app-user-sidebar',
  templateUrl: './user-sidebar.component.html',
  styleUrls: ['./user-sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('sideMenu', [
      transition(':enter', [
        useAnimation(SidebarOpenAnimation, {
          params: {
            ...animationParams,
          },
        }),
      ]),
      transition(':leave', [
        useAnimation(SidebarCloseAnimation, {
          params: {
            ...animationParams,
          },
        }),
      ]),
    ]),
  ],
})
export class UserSidebarComponent extends WithSubscription(Object) implements OnInit {
  @Input() isOpen: boolean;

  @Output() close = new EventEmitter();

  protected isDev: boolean = false;
  protected readonly isNative = 'native' in window;

  protected company: Company = new Company();
  protected person: Person = new Person();
  protected advisor: Advisor;
  protected advisorLoading: boolean = true;
  protected consentType = ConsentTypeEnum;

  protected logoutSpinner: boolean = false;

  constructor(
    private readonly userSettingsService: UserSettingsService,
    @Inject(AUTH_SERVICE_TOKEN) protected readonly authService: AuthService,
    private readonly modalService: BsModalService,
    private readonly consentService: ConsentService,
    private readonly personUpdateService: PersonUpdateService,
    private readonly router: Router,
    private readonly uiChangeTriggersService: UiChangeTriggersService,
  ) {
    super();
  }

  public ngOnInit(): void {
    if (isDevMode()) {
      this.isDev = true;
    }

    this.updateUserSettings();
    this.listenToSubject(this.userSettingsService.profileDataChangedSubject, () => this.updateUserSettings());
  }

  protected openAdvisorCard(): void {
    const advisorModalRef = this.modalService.show(AgentModalComponent, {});
    advisorModalRef.content.advisor = this.advisor;
    advisorModalRef.content.person = this.person;
    advisorModalRef.content.company = this.company;
    advisorModalRef.content.mandateApproval = this.person?.mandateApproval;

    advisorModalRef.content.closeAdvisorModal.subscribe((): void => {
      advisorModalRef.hide();
    });
    this.closeActiveMenu();
  }

  protected navigateToOtherPage(page: string): void {
    this.router.navigate([page]);
    this.closeActiveMenu();
  }

  protected goToOtherExternalUrl(url: string): void {
    window.open(url, '_blank');
    this.closeActiveMenu();
  }

  protected logout(): void {
    this.logoutSpinner = true;
    this.authService.logout();
  }

  protected closeActiveMenu(): void {
    this.close.emit(false);
  }

  protected consentForCompany(con: ConsentTypeEnum): void {
    this.consentService.consentByTypeAndCompany(ConsentTypeEnum[con], this.company.id).subscribe({
      next: (data): void => {
        const consentModalRef = this.modalService.show(ConsentModalComponent, {});
        consentModalRef.content.title = data.title;
        consentModalRef.content.description = data.html;

        consentModalRef.content.action.subscribe((value: boolean): void => {
          if (value === true) {
            consentModalRef.hide();
          }
        });
      },
      error: (error): void => {
        console.log(error);
      },
    });
  }

  protected consent(con: ConsentTypeEnum): void {
    this.consentService.consentByType(ConsentTypeEnum[con]).subscribe({
      next: (data): void => {
        const consentModalRef = this.modalService.show(ConsentModalComponent, {});
        consentModalRef.content.title = data.title;
        consentModalRef.content.description = data.html;

        consentModalRef.content.action.subscribe((value: boolean): void => {
          if (value === true) {
            consentModalRef.hide();
          }
        });
      },
      error: (error): void => {
        console.log(error);
      },
    });
  }

  protected openThemeModal(): void {
    const optionsWidgetModalRef = this.modalService.show(OptionsWidgetModalComponent, {});

    optionsWidgetModalRef.content.options = [
      { icon: 'sun', text: 'Light Mode', event: 'light' },
      { icon: 'moon', text: 'Dark Mode', event: 'dark' },
      { icon: 'layers', text: 'Automatisch', event: 'auto' },
      { icon: 'x-circle', text: 'Schließen', event: null },
    ];

    optionsWidgetModalRef.content.action.subscribe((value: Theme | null): void => {
      if (value) {
        this.uiChangeTriggersService.themeSetting = value;
      } else {
        optionsWidgetModalRef.hide();
      }
    });
  }

  private updateUserSettings(): void {
    this.userSettingsService.getUserProfile().subscribe({
      next: (data: Person): void => {
        this.person = data;
        this.advisor = data.advisor;
        this.company = data.advisor.company;
        this.advisorLoading = false;

        // avatar update from the settings subscription
        setTimeout((): void => {
          this.personUpdateService.getUpdatedPerson().subscribe({
            next: (value: Person): void => {
              if (value && value?.profilePictureUrl) {
                this.person = value;
              }
            },
            error: (error): void => {
              console.log(error);
            },
          });
        }, 1000);
      },
      error: (error): void => {
        console.log(error);
      },
    });
  }
}
