import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { BackgroundInformationComponent } from '../background-information/background-information.component';

import { ModalsSafeAreaService } from '../../../global-store';

import { ConsentTypeEnum } from '../../../enum';

@Component({
  standalone: true,
  selector: 'app-vinvlivt-consents',
  templateUrl: './vinvlivt-consents.component.html',
  styleUrls: ['./vinvlivt-consents.component.scss'],
  imports: [BackgroundInformationComponent],
})
export class VinvlivtConsentsComponent implements OnInit {
  @Output() action = new EventEmitter();

  public safeAreaBottom: number;

  // public backgroundInformation: boolean = false;
  // public consentType: string;
  // public consent = ConsentTypeEnum;
  public title: string;
  public description: string;

  constructor(private modalsSafeAreaService: ModalsSafeAreaService) {}

  public ngOnInit(): void {
    this.safeAreaBottom = this.modalsSafeAreaService.getSafeAreaBottom();
  }

  protected redirectToExternalUrl(url: string): void {
    window.open(url, '_blank');
    // this.consentType = ConsentTypeEnum[consentType];
    // this.backgroundInformation = value;
  }
}
