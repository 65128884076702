<div class="contract-approved-view-container">
  <div class="row">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-center">
        <img
          src="https://static-assets.vinlivt.de/icons/premium/purple/320/Folder 1.png"
          width="120"
          style="min-width: 120px"
          alt="folder image"
        />
      </div>
    </div>
  </div>

  <div class="row mt-20 mb-50">
    <div class="col-12 text-center">
      <div class="font-size-h4 theme-color">Mein Maklermandat</div>
    </div>
  </div>

  @if (brokerMandate?.status === 'APPROVED') {
    <div class="row mt-10">
      @for (type of types; track type.type) {
        <div class="col-8 d-flex align-items-center">
          <i class="bi bi-filetype-pdf theme-color mr-3 text-20"></i>

          <div class="theme-color">{{ type.name }}</div>
        </div>

        <div class="col-4 text-right">
          <a class="text-purple mr-3" href="javascript:void(0)" (click)="downloadPDF.emit(type.type)">
            <i class="bi bi-download theme-color text-20"></i>
          </a>

          <a class="text-purple" href="javascript:void(0)" (click)="showPreview.emit(type.type)">
            <i class="bi bi-eye theme-color text-20"></i>
          </a>
        </div>
      }
    </div>
  }

  <div class="row mt-30">
    <div class="col-12">
      <div class="theme-color text-center">
        Wenn du Fragen zum Maklermandat hast, kontaktierte bitte deinen persönlichen Berater.
        <i class="bi bi-info-circle text-purple ml-1"></i>
      </div>
    </div>
  </div>

  <div class="row mt-50">
    <div class="col-4 pl-md-0">
      <button type="button" class="btn text-purple" (click)="closeModal.emit()">Schließen</button>
    </div>

    <div class="col-8 text-right">
      <button type="button" class="btn btn-rounded btn-purple" (click)="redirectToVinhub()">Kontaktieren</button>
    </div>
  </div>
</div>
