<div class="signing-pad-container d-flex flex-column">
  @if (editMode) {
    <canvas id="signing-pad" #signPad width="350" height="200"></canvas>
  } @else {
    <img [src]="imgSignature" alt="signature image" />
  }

  <div class="d-flex align-items-center justify-content-center mt-15">
    @if (editMode && !signPadRender) {
      <button class="btn btn-rounded purple-outline-btn mr-10" (click)="clearSignature()">
        <i class="bi bi-arrow-clockwise mr-1"></i>
        <span>Zurücksetzen</span>
      </button>

      <button
        class="btn btn-rounded btn-purple"
        id="accept-signature"
        [disabled]="signature.isEmpty()"
        (click)="saveSignature()"
      >
        <i class="bi bi-check-lg mr-1"></i>
        <span>Übernehmen</span>
      </button>
    } @else {
      <button class="btn btn-rounded purple-outline-btn mr-10" (click)="editSignature()">
        <i class="bi bi-arrow-clockwise mr-1"></i>
        <span>Zurücksetzen</span>
      </button>
    }
  </div>
</div>
