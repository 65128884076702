export enum GermanStatesEnum {
  BADEN_WUERTTEMBERG = 'Baden Württemberg',
  BAVARIA = 'Bayern',
  BERLIN = 'Berlin',
  BRANDENBURG = 'Brandenburg',
  BREMEN = 'Bremen',
  HAMBURG = 'Hamburg',
  HESSE = 'Hessen',
  MECKLENBURG_WEST_POMERANIA = 'Mecklenburg-Vorpommern',
  LOWER_SAXONY = 'Niedersachsen',
  NORTHRINE_WESTPHALIA = 'Nordrhein Westfalen',
  RHINELAND_PALATINATE = 'Rheinland-Pfalz',
  SAARLAND = 'Saarland',
  SAXONY = 'Sachsen',
  SAXONY_ANHALT = 'Sachsen-Anhalt',
  SCHLESWIG_HOLSTEIN = 'Schleswig-Holstein',
  THURINGIA = 'Thüringen',
}
