import { Attachment } from './attachment.model';

export interface IDamageReport {
  created: Date;
  id: string;
  title: string;
  description: string;
  attachments: Attachment[];
}

export interface IPostDamageReport {
  contractUid: string;
  title: string;
  description: string;
}
