import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';

import { BackgroundInformationComponent } from '../background-information/background-information.component';

import { ModalsSafeAreaService } from '../../../global-store';

import { Company } from '../../../model';

import { ConsentTypeEnum } from '../../../enum';

@Component({
  standalone: true,
  selector: 'app-advisor-check-modal',
  templateUrl: './advisor-check-modal.component.html',
  styleUrls: ['./advisor-check-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [BackgroundInformationComponent, NgOptimizedImage],
})
export class AdvisorCheckModalComponent implements OnInit {
  @Output() action = new EventEmitter();

  public safeAreaBottom: number;

  public backgroundInformation: boolean = false;
  public consentType: string;
  public company: Company = new Company();
  public consent = ConsentTypeEnum;
  public title: string;
  public description: string;

  constructor(private modalsSafeAreaService: ModalsSafeAreaService) {}

  public ngOnInit(): void {
    this.safeAreaBottom = this.modalsSafeAreaService.getSafeAreaBottom();
  }

  protected showBackGroundInformation(value: boolean, consentType: ConsentTypeEnum): void {
    this.consentType = ConsentTypeEnum[consentType];
    this.backgroundInformation = value;
  }
}
