<div class="onboarding-property-ownership-step-container">
  <div class="row">
    <div class="col-12">
      <h3 class="block-title font-size-h4 fw-400 mb-20">Besitzt du bereits Immobilien?</h3>
    </div>
  </div>

  <div class="row">
    <div class="col-12 mb-20">
      <div class="align-items-center">
        <span class="icon-container theme-color d-flex align-items-center gap-1">
          <i class="bi bi-info-circle"></i>
          Mehrfachauswahl möglich!
        </span>
      </div>
    </div>
  </div>

  <div class="middle-content mb-15">
    <div class="row stepper">
      <!--  {{ pensionCheck.provisionTarget }}-->
      @for (entry of propertyOwnership | keys; track entry) {
        <div class="mb-0" [ngClass]="{ 'col-md-12': enumObject.length <= 7, 'col-md-6': enumObject.length > 7 }">
          <div class="selection-wrapper">
            <label for="inputfield-{{ entry.key }}" class="selected-label">
              <input
                type="checkbox"
                name="inputfield-{{ entry.key }}"
                id="inputfield-{{ entry.key }}"
                [checked]="personProfile?.propertyOwnership && personProfile?.propertyOwnership?.includes(entry.key)"
                (click)="setValue(entry.key)"
              />
              <div class="selected-content">
                <h4>{{ entry.value }}</h4>
              </div>
            </label>
          </div>
        </div>
      }
    </div>
  </div>

  <div class="row">
    <div class="col-6 text-left py-2">
      <div class="theme-secondary-color pointer-cursor" (click)="triggerPreviousStep.emit()">
        <i class="bi bi-arrow-left mr-2"></i> Zur&uuml;ck
      </div>
    </div>

    <div class="col-6 text-right">
      <button
        type="button"
        [disabled]="!personProfile?.propertyOwnership?.length"
        class="btn btn-rounded btn-purple px-20"
        (click)="triggerNextStep.emit()"
      >
        Weiter
        <i class="bi bi-arrow-right ml-2"></i>
      </button>
    </div>
  </div>
</div>
