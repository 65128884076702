import { Component, Input } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { AgentModalComponent } from '../../modals/agent-modal/agent-modal.component';

import { Advisor, MandateApproval } from '../../model';

@Component({
  standalone: true,
  selector: 'app-advisor-micro-card',
  templateUrl: 'advisor-micro-card.component.html',
  styleUrls: ['advisor-micro-card.component.scss'],
  imports: [NgxSkeletonLoaderModule],
})
export class AdvisorMicroCardComponent {
  @Input() loading: boolean;
  @Input() advisorInfo: Advisor = new Advisor();
  @Input() mandateApproval: MandateApproval = new MandateApproval();
  @Input() advisorFullName: boolean = false;

  // not used inputs
  @Input() showRightImg: boolean = false;
  @Input() showVinhubRedirectButton: boolean = false;

  constructor(private modalService: BsModalService) {}

  protected showAdvisorModal(): void {
    const advisorModalRef = this.modalService.show(AgentModalComponent, {});
    advisorModalRef.content.advisor = this.advisorInfo;
    advisorModalRef.content.mandateApproval = this.mandateApproval;

    advisorModalRef.content.closeAdvisorModal.subscribe((): void => {
      advisorModalRef.hide();
    });
  }
}
